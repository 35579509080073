import React from "react";
import { Box } from "@mui/material";
import Arrow from "../../../assets/icons/Arrow.webp";

const ArrowButton = (props: any) => {
  return (
    <div>
      <Box
        onClick={props.onClick}
        sx={{
          backgroundColor: "transparent",
          border: "1px solid #222",
          width: "2rem",
          height: "2rem",
          borderRadius: "100px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          "&:hover": {
            cursor: "pointer",
            backgroundColor: "#6C30B9",
          },
        }}
      >
        <img src={Arrow} width="12px" height="12px" alt="Arrow" title="Arrow" loading="lazy" />
      </Box>
    </div>
  );
};

export default ArrowButton;
