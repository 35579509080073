import React from "react";
import { Container, Box, Typography } from "@mui/material";

const ContactInfo = () => {
  return (
    <div>
      <Container
        disableGutters
        maxWidth="xl"
        sx={{
          bgcolor: "#f4f4f4",
        }}
      >
        <Container
          maxWidth="md"
          sx={{
            display: "flex",
            flexDirection: "column",
            paddingTop: "4rem",
            paddingBottom: "4rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              flexDirection: "column",
              alignItems: "flex-start",
              borderTop: "1px solid #000",
              borderBottom: "1px solid #000",
              paddingTop: "1rem",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-start",
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  textTransform: "uppercase",
                  fontSize: "1rem",
                  margin: "0rem",
                  boxSizing: "0",
                }}
              >
                Phone
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={{
                  fontFamily: "Inter",
                  display: "flex",
                  justifyContent: "center",
                  paddingTop: "1rem",
                  paddingBottom: "1rem",
                  paddingLeft: "3rem",
                  fontSize: {
                    sm: "1rem",
                    md: "1rem",
                    lg: "1.25rem",
                    xl: "1.25rem",
                  },
                  fontWeight: 500,
                }}
              >
                0705888806
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              flexDirection: "column",
              alignItems: "flex-start",
              borderBottom: "1px solid #000",
              paddingTop: "1rem",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-start",
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  textTransform: "uppercase",
                  fontSize: "1rem",
                }}
              >
                Email
              </Typography>
            </Box>
            <Box sx={{ width: "100%" }}>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Inter",
                  flexWrap: "wrap",
                  display: "flex",
                  paddingTop: "1rem",
                  paddingBottom: "1rem",
                  paddingLeft: "3rem",
                  fontSize: {
                    sm: "1rem",
                    md: "1rem",
                    lg: "1.25rem",
                    xl: "1.25rem",
                  },
                  textTransform: "uppercase",
                  fontWeight: 500,
                }}
              >
                rashdan@sthlmtechpartner.com
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              flexDirection: "column",
              alignItems: "flex-start",
              borderBottom: "1px solid #000",
              paddingTop: "1rem",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-start",
              }}
            >
              <Typography
                variant="body1"
                sx={{ textTransform: "uppercase", fontSize: "1rem" }}
              >
                Address
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={{
                  fontFamily: "Inter",
                  display: "flex",
                  justifyContent: "center",
                  paddingTop: "1rem",
                  paddingBottom: "1rem",
                  fontSize: {
                    sm: "1rem",
                    md: "1rem",
                    lg: "1.25rem",
                    xl: "1.25rem",
                  },
                  fontWeight: 500,
                  paddingLeft: "3rem",
                }}
              >
                MALMSKILLNADSGATAN 32, 11151 STOCKHOLM
              </Typography>
            </Box>
          </Box>
        </Container>
      </Container>
    </div>
  );
};

export default ContactInfo;
