import React, { Fragment } from "react";
import { CssBaseline, Container, Box, Typography } from "@mui/material";
import Collaboration from "../../../assets/images/AboutBuildiOSApp.webp";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import PrimaryButton from "../../atom/PrimaryButton/PrimaryButton";

const useStyles = makeStyles((theme: any) => ({
  aboutMobileView: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
    },
  },
  textWidthControl: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.up("md")]: {
      width: "88%",
    },
  },
  aboutMobileImg: {
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
      borderRadius: "10px",
    },
    [theme.breakpoints.up("sm")]: {
      maxWidth: "100%",
      borderRadius: "10px",
    },
  },
  typoMargin: {
    [theme.breakpoints.down("sm")]: {
      marginTop: "2rem",
    },
  },
}));

const AboutUsSection = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <Fragment>
      <CssBaseline />
      <Container disableGutters maxWidth="xl" sx={{ bgcolor: "#fff" }}>
        <Container
          maxWidth="md"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            paddingTop: "2rem",
            paddingBottom: "4rem",
          }}
          className={classes.aboutMobileView}
        >
          <Box
            sx={{ display: "flex", justifyContent: "space-between" }}
            className={classes.aboutMobileView}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="h2"
                sx={{
                  fontSize: {
                    lg: "1rem",
                    md: "1rem",
                    sm: "1.5rem",
                    xs: "1.5rem",
                  },
                  fontWeight: 550,
                  color: "#000",
                  marginBottom: "1.5rem",
                }}
              >
                About us
              </Typography>

              <Box className={`${classes.textWidthControl}`}>
                <img
                  src={Collaboration}
                  style={{
                    borderRadius: "5px",
                  }}
                  width="100%"
                  height="97%"
                  className={classes.aboutMobileImg}
                  alt="AboutBuildiOSApp"
                  loading="lazy"
                  title="AboutBuildiOSApp"
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
              }}
              className={classes.textWidthControl}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                }}
                className={classes.typoMargin}
              >
                <Typography
                  variant="body1"
                  sx={{
                    fontFamily: "Noto Serif",
                    marginBottom: ".75rem",
                    color: "#000",
                    fontSize: {
                      xl: "1.15rem",
                      lg: "1.15rem",
                      md: "1.15rem",
                      sm: "1.35rem",
                      xs: "1.35rem",
                    },
                    fontWeight: 500,
                  }}
                >
                  We are a leading top app development company assisting in iphone, android, and hybrid mobile apps based in Stockholm, Sweden. We create social
                  impact!
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    fontFamily: "Noto Serif",
                    fontWeight: 500,
                    color: "#000",
                    fontSize: {
                      xl: "1.15rem",
                      lg: "1.15rem",
                      md: "1.15rem",
                      sm: "1.35rem",
                      xs: "1.35rem",
                    },
                    marginBottom: "1rem",
                  }}
                >
                  Sthlmtechpartner is a passionate advocate for Gender Equality
                  issues, as our core business revolves around app development
                  services and supporting females in the IT and Tech industry.
                </Typography>
                <Box sx={{ width: "7rem", marginTop: "1rem" }}>
                  <PrimaryButton
                    title="About Us"
                    onClick={() => navigate("/about")}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Container>
      </Container>
    </Fragment>
  );
};

export default AboutUsSection;
