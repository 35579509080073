import React from "react";
import { Helmet } from "react-helmet-async";
import Blog from "../../components/organism/Blog/Blog";

const BlogPage = () => {
  return (
    <div>
      <Helmet>
        <title>Blog</title>
        <meta name="description" content="Software Development is more or less similar to project management considering it goes through similar phases of planning and execution." />
        <link rel="canonical" href="/blogs" />
      </Helmet>
      <Blog maxWidth="false" />
    </div>
  );
};

export default BlogPage;
