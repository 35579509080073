import React from "react";
import { Helmet } from "react-helmet-async";
import Contact from "../../components/organism/Contact/Contact";

const ContactPage = () => {
  return (
    <div>
      <Helmet>
        <title>Contact</title>
        <meta name="description" content="Could you be our newest friend? We certainly hope so. Don't hesitate to say hi and meet our team." />
        <link rel="canonical" href="/contacts" />
      </Helmet>
      <Contact maxWidth="false" />
    </div>
  );
};

export default ContactPage;
