import React, { Fragment, useState } from "react";
import { CssBaseline, Container, Box, Typography } from "@mui/material";
import Navbar from "../../molecule/Navbar/Navbar";
import PortfolioCard from "../../molecule/PortfolioCard/PortfolioCard";
import SecondaryButton from "../../atom/SecondaryButton/SecondaryButton";
import ContactForm from "../../molecule/ContactForm/ContactForm";
import Footer from "../../molecule/Footer/Footer";
import { makeStyles } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { PortfolioCardContent } from "./PortfolioContent";

const useStyles = makeStyles((theme: any) => ({
  container: {
    [theme.breakpoints.down("630")]: {
      display: "flex",
      flexDirection: "column",
      justifyConent: "center",
    },
  },
}));

export interface Style {
  maxWidth: any;
}

const Portfolio = (props: Style) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const styleComp = (
    <div style={{ color: "#212121" }}>
      Tell us your problem and get the
      <span
        style={{
          color: "#6D30B9",
          fontFamily: "Noto Serif",
          fontWeight: "bold",
        }}
      >
        {" "}
        best services for you
      </span>{" "}
    </div>
  );



  const [visible, setVisible] = useState(6);

  const handleShowMorePosts = () => {
    setVisible((prevState) => prevState + 6);
  };

  return (
    <Fragment>
      <CssBaseline />
      <Navbar />
      <Container
        disableGutters
        maxWidth={props.maxWidth}
        sx={{
          paddingTop: "8rem",
        }}
      >
        <Container
          maxWidth="md"
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
          className={classes.container}
        >
          <Navbar />
          <Typography variant="body1">Ready to make it happen</Typography>
          <Typography
            variant="h1"
            sx={{ fontFamily: "Noto Serif", fontSize: '2rem', marginBottom: "3rem" }}
          >
            Our works
          </Typography>
          <Container maxWidth="lg" disableGutters>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
              className={classes.container}
            >
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                }}
                className={classes.container}
              >
                {PortfolioCardContent.slice(0, visible).map((item, index) => {
                  return (
                    <Box
                      sx={{
                        marginTop: {
                          xl: item.margin,
                          lg: item.margin,
                          md: item.margin,
                          sm: "0rem",
                          xm: "0rem",
                        },
                        width: {
                          xl: "40%",
                          lg: "40%",
                          md: "40%",
                          sm: "100%",
                          xs: "100%",
                        },
                        marginBottom: "1rem",
                      }}
                      key={index}
                    >
                      <PortfolioCard
                        title={item.title}
                        description={item.description}
                        backgroundImg={item.backgroundImg}
                        onClick={() =>
                          navigate("/works/portfolio", {
                            state: item,
                          })
                        }
                      />
                    </Box>
                  );
                })}
              </Box>
            </Box>
          </Container>
          <Box
            sx={{
              marginTop: "2rem",
              marginBottom: "2rem",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <SecondaryButton onClick={handleShowMorePosts} title="Watch more" />
          </Box>
        </Container>
        <Container
          sx={{ bgcolor: "#f4f4f4" }}
          maxWidth={props.maxWidth}
          disableGutters
        >
          <ContactForm
            title="Interested in working with us?"
            description={styleComp}
          />
        </Container>
        <Footer />
      </Container>
    </Fragment>
  );
};

export default Portfolio;
