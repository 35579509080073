import React from "react";
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  Container,
} from "@mui/material";
import { Link, useNavigate, NavLink } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import Logo from "../../../assets/other/LogoFile.webp";
import LogoType from "../../../assets/other/LogoType.webp";
import PrimaryButton from "../../atom/PrimaryButton/PrimaryButton";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => ({
  navStyle: {
    textDecoration: "none",
    color: "#303030",
    padding: "1.25rem",
    fontFamily: "Inter",
    fontSize: ".75rem",
  },
}));

const Navbar = () => {
  const navigate = useNavigate();
  const classes = useStyles();

  const NavbarComponent = () => {
    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
      null
    );

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = (index: any) => {
      setAnchorElNav(null);
    };

    return (
      <AppBar
        position="absolute"
        elevation={0}
        sx={{
          backgroundColor: {
            xl: "transparent",
            lg: "transparent",
            md: "transparent",
            sm: "#F4F4F4",
            xs: "#F4F4F4",
          },
          padding: "1rem 0",
        }}
      >
        <Container maxWidth="md">
          <Toolbar disableGutters>
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ mr: 2, display: { xs: "none", md: "flex", color: "#000" } }}
            >
              <Link to="/">
                <img src={LogoType} width="164.1px"
                  height="60px"
                  loading="lazy"
                  alt="LogoType" title="LogoType" />
              </Link>
            </Typography>

            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "flex", md: "none" },
              }}
            >
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon
                  style={{
                    color: "#000",
                  }}
                />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "1rem",
                  }}
                >
                  <Link
                    to="/"
                    style={{
                      textDecoration: "none",
                      color: "#303030",
                    }}
                  >
                    Home
                  </Link>
                  <Link
                    to="/about"
                    style={{
                      textDecoration: "none",
                      color: "#212121",
                    }}
                  >
                    About
                  </Link>
                  <Link
                    to="/services"
                    style={{ textDecoration: "none", color: "#212121" }}
                  >
                    Services
                  </Link>
                  <Link
                    to="/works"
                    style={{ textDecoration: "none", color: "#212121" }}
                  >
                    Works
                  </Link>
                  <Link
                    to="/blogs"
                    style={{ textDecoration: "none", color: "#212121" }}
                  >
                    Blogs
                  </Link>
                  <Link
                    to="/contacts"
                    style={{ textDecoration: "none", color: "#212121" }}
                  >
                    Contacts
                  </Link>
                </Box>
              </Menu>
            </Box>
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}
            >
              <img src={Logo} alt="logo" width="37.6px"
                height="40px"
                title="logo"
                loading="lazy" />
            </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              <Box
                sx={{
                  display: "flex",
                }}
              >
                <NavLink
                  to="/"
                  className={classes.navStyle}
                  style={({ isActive }) => {
                    return {
                      color: isActive ? "#6D30B9" : "none",
                      fontWeight: isActive ? "bold" : "none",
                    };
                  }}
                >
                  Home
                </NavLink>
                <NavLink
                  to="/about"
                  className={classes.navStyle}
                  style={({ isActive }) => {
                    return {
                      color: isActive ? "#6D30B9" : "none",
                      fontWeight: isActive ? "bold" : "none",
                    };
                  }}
                >
                  About
                </NavLink>
                <NavLink
                  to="/services"
                  className={classes.navStyle}
                  style={({ isActive }) => {
                    return {
                      color: isActive ? "#6D30B9" : "none",
                      fontWeight: isActive ? "bold" : "none",
                    };
                  }}
                >
                  Services
                </NavLink>
                <NavLink
                  to="/works"
                  className={classes.navStyle}
                  style={({ isActive }) => {
                    return {
                      color: isActive ? "#6D30B9" : "none",
                      fontWeight: isActive ? "bold" : "none",
                    };
                  }}
                >
                  Works
                </NavLink>
                <NavLink
                  to="/blogs"
                  className={classes.navStyle}
                  style={({ isActive }) => {
                    return {
                      color: isActive ? "#6D30B9" : "none",
                      fontWeight: isActive ? "bold" : "none",
                    };
                  }}
                >
                  Blogs
                </NavLink>
                <NavLink
                  to="/contacts"
                  className={classes.navStyle}
                  style={({ isActive }) => {
                    return {
                      color: isActive ? "#6D30B9" : "none",
                      fontWeight: isActive ? "bold" : "none",
                    };
                  }}
                >
                  Contacts
                </NavLink>
              </Box>
            </Box>

            <Box sx={{ flexGrow: 0 }}>
              <PrimaryButton
                title="Contact Us"
                onClick={() => navigate("/contacts")}
              />
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    );
  };

  return (
    <div>
      <NavbarComponent />
    </div>
  );
};

export default Navbar;
