import React, { Fragment } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import PortfolioSingle from "./components/organism/PortfolioSingle/PortfolioSingle";
import AboutUsPage from "./pages/AboutUsPage/AboutUsPage";
import BlogPage from "./pages/BlogPage/BlogPage";
import ContactPage from "./pages/ContactPage/ContactPage";
import HomePage from "./pages/HomePage/HomePage";
import PortfolioPage from "./pages/PortfolioPage/PortfolioPage";
import BlogSingle from "./components/organism/BlogSingle/BlogSingle";
import ScrollToTop from "./components/other/ScrollToTop/ScrollToTop";
import Services from "./components/organism/Services/Services";
import Navbar from "./components/molecule/Navbar/Navbar";

function App() {

  return (
    <div>
      <Fragment>
        <BrowserRouter>
          <Navbar />
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/works" element={<PortfolioPage />} />
            <Route path="/about" element={<AboutUsPage />} />
            <Route path="/blogs" element={<BlogPage />} />
            <Route path="/services" element={<Services />} />
            <Route path="/contacts" element={<ContactPage />} />
            <Route path="/blogs/:blogDetail" element={<BlogSingle />} />
            <Route
              path="/works/portfolio"
              element={<PortfolioSingle width={false} />}
            />
          </Routes>
        </BrowserRouter>
      </Fragment>
    </div>
  );
}

export default App;
