import { Container, Box, Typography } from "@mui/material";
import Vector from "../../../assets/images/Vector.webp";
import Rawan from "../../../assets/images/Rawan.webp";
import Facebook from "../../../assets/images/Facebook.webp";
import Twitter from "../../../assets/images/Twitter.webp";
import LinkedIn from "../../../assets/images/LinkedIn.webp";
import Instagram from "../../../assets/images/Instagram.webp";
import Footer from "../../molecule/Footer/Footer";
import { makeStyles } from "@material-ui/core";
import BlogCard from "../../molecule/BlogCard/BlogCard";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import ArrowButton from "../../atom/ArrowButton/ArrowButton";




const useStyles = makeStyles((theme: any) => ({
  ImageInside: {
    [theme.breakpoints.down("700")]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  },
  subBox: {
    [theme.breakpoints.down("700")]: {
      paddingBottom: "1rem",
    },
  },
  ImagesBox: {
    [theme.breakpoints.down("800")]: {
      display: "flex",
      flexWrap: "wrap",
    },
  },
  imgMain: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      width: "80%",
    },
  },
  blogSingleType: {
    [theme.breakpoints.down("630")]: {
      display: "flex",
      flexDirection: "column",
    },
  },
  insideBoxes: {
    [theme.breakpoints.down("800")]: {
      paddingBottom: "2rem",
    },
  },
  socialHandle: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  socialHide: {
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
}));


const blogCardContent = [
  {
    description:
      "Tactics & Rules in Software Development where Team Support is Required",
    backgroundImage: require("../../../assets/images/WomenEmpoweriOSApp.webp"),
    // arrowButton: <ArrowButton />,
    content: {
      url: "software-development-tactics",
      subHeading: "Blog",
      mainHeading:
        "Tactics & Rules in Software Devolpment where Team Support is Required",
      author: "by Rawan",
      date: "May 5th 2021",
      contentImage: require("../../../assets/images/WomenEmpowerAndroidApp.webp"),
      bodyContent: {
        type: "normal",
        boldParagraph:
          "Software Building is a Team Support which require certain rules and tactics for a team to work collaboratively. Following is a brief description of what rules must be considered for teams to work efficiently!",
        regularParagraph:
          "Software Building is more or less similar to project management considering it goes through similar phases of initiation, planning, execution, and evaluation which in turn either leads to completion or modification. Under these broad categories of technology, a team must go through programming, implementation, developing, analyzing, designing, and testing together on the prototypes. ",
        italicParagraph:
          "Software Building is more or less similar to project management considering it goes through similar phases of initiation, planning, execution, and evaluation which in turn either leads to completion or modification. Under these broad categories of technology, a team must go through programming, implementation, developing, analyzing, designing, and testing together on the prototypes. ",
        regularParagraphtype:
          "Following is a list of few agile principles that a team require to follow to be supportive towards the project as a group: -Customer Satisfaction- is all time necessary objective that a team must actively work towards. -Change- Change can be hard to accept especially in a group of diverse people where everyone might react differently, but a successful team would harness the change’s potential towards the client’s advantage.",
        bodyHeading: "Diversity in frameworks",
        bodyParagraph:
          "A team with no deadline might slow down in their work but it is essential to deliver frequently on a short timescale for work to be in line with the client’s expectation. -Daily Tasks- Group members must work closely on a day-to-day basis on the tasks to be able to get constructive feedback regularly and to be aware of any potential changes in the framework. That is why a common brief of the tasks should be agreed upon by the concerned parties. -Sustainability- Being sustainable in your actions is not only important for the team to feel that their work is valued and sustainable, but it is also one of the sustainability goals to achieve a sustainable work in progress. -Self-Organization- Self-Organizing skills are important in diverse agile teams and can prove very effective in the tasks being accomplished on time. -Feedback- Feedback is essential in software manufacturing where the clients must also be involved with the team to make sure the tasks haven’t lost focus and the project is being run smoothly according to the expectations of the client. Other than these rules a software engineering team must use few tactics such as project estimation techniques which are used to estimate how much work will a specific task take? There are two ways to estimate that such as analogous estimation and parametric estimation. Analogous estimation is when the scope is estimated in comparison to similar previous projects. ",
      },
    },
  },
  {
    description: "Social Impact in Tech Through Sustainable Goals",
    backgroundImage: require("../../../assets/images/SocialImpact.webp"),
    // arrowButton: <ArrowButton />,
    content: {
      url: "social-impact",
      subHeading: "Blog",
      mainHeading: "Social Impact in Tech Through Sustainable Goals",
      author: "by Rawan",
      date: "May 5th 2021",
      contentImage: require("../../../assets/images/SocialImpact.webp"),
      bodyContent: {
        type: "normal",
        boldParagraph:
          "Sustainability has a great importance in start-up culture. Modern technology as well as awareness on sustainability has made it easier for small companies to adapt to sustainable practices. Most start-ups however focus on innovation rather than sustainability. We at STHLM Tech Partner believe that a start-up is a perfect place to use and implement sustainable practices.",
        regularParagraph:
          "Start-ups are usually fixated on the notion of innovation. Sometimes this notion of innovation creates less and less space for sustainability and social impact which is a necessity for today’s start-up culture. This creates a decline in sustainable practices among start-ups. ",
        italicParagraph:
          "Companies need to generate social impact by focusing on sustainable goals. The Sustainable Development Goals (SDG) are considered a “blueprint to achieve a better and more sustainable future for all”. This blueprint has been drafted by keeping in mind some of the most important international goals to be achieved.",
        regularParagraphtype:
          "The SDGs were set up in 2015 by the United Nations General Assembly and are intended to be achieved by the year 2030. These goals can be broken down into five elements which are people, planet, prosperity, peace and partnerships.",
        bodyHeading: "These sustainable goals are illustrated as follows:",
        bodyParagraph:
          "A picture containing text, outdoor Description automatically generated The Oxford Dictionary defines sustainability as “the avoidance of the depletion of natural resources to maintain an ecological balance”. This ecological balance represents a lot of sustainable goals but especially Goal number 5 “gender equality” and Goal number 10 “reduced inequality”. We believe that all these goals are worth working for but at STHLM Tech Partner, we have a passion for Gender Equality, Reduced Inequalities, and Decent Work and Economic Growth that we constantly work towards. The reason why we focus on these goals is that we would like to create a social impact along with providing our services. And what better way to create sustainable social impact than focusing on some of the most important SDG Goals. The question arises here that how can we achieve these goals? We asked the same question in the beginning and then realized that women in tech are not being provided with equal work opportunities even in developed countries. ",
      },
    },
  },
  {
    description: "Mobile Development Trends to Watch in 2021",
    backgroundImage: require("../../../assets/images/MobileDevelopment.webp"),
    // arrowButton: <ArrowButton />,
    content: {
      url: "mobile-development-trends",
      subHeading: "Blog",
      mainHeading: "Mobile Development Trends to Watch in 2021",
      author: "by Rawan",
      date: "May 5th 2021",
      contentImage: require("../../../assets/images/MobileDevelopment.webp"),
      bodyContent: {
        type: "normal",
        boldParagraph:
          "At STHLM Tech Partner, we believe that information is the key to success. We are experts in our field of information which is building Apps and we have compiled a list of trends that show a potential of making an impact in the market in 2022.",
        regularParagraph:
          "When it comes to application building, one must consider the importance of it in their respective fields such as app development in the Education Industry, Healthcare Industry, Fitness Industry, Sports Industry, and Sales Industry, etc. For example, the E-learning market is at all times high with 375$ billion revenue generation to be possibly exceeding by 2026. This is just one example of how app development is at the core of a multi-billion revenue-generating industry.",
        italicParagraph:
          "One of the most famous app trends of 2021 is the Internet of Things (IoT). Several businesses are shifting their strategies to IoT by changing the application of manual machinery to IoT in order to access and operate the task through their mobile devices. The potential of future apps in this category includes smart home applications, IoT in healthcare, and AI-powered IoT devices.",
        regularParagraphtype:
          "Mostly AI and ML are restricted to virtual assistant applications or AI-based photo filtering applications, but it is so much more than these two categories. AI and ML can play a big role in enhancing already existing tools and applications. This type of app development also helps in understanding the AI powered transition of already existing data which helps in solving real-time problems. The potential of future apps in this category includes but is not restricted to voice and language translations, cybersecurity, and user behaviour predictions.",
        bodyHeading: "Enterprise Mobile Applications",
        bodyParagraph:
          "This category of application is in demand for the last few years and some of the examples include Uber, Foodora, Zomato, and Airbnb. These applications make a person’s life easier by making access to daily necessities such as healthcare, food, transportation, and accommodation easy. This industry has huge potential in terms of apps with some of the potential future applications including on-demand beauty applications, service industry applications, sales-oriented applications, and domestic helper applications. Cloud Technology is a fast-growing industry but a new one in terms of mobile apps in 2021. Cloud Integration benefits the businesses as well as the users and takes off the pressure of having extra space for other applications.",
      },
    },
  },
];


export interface BlogData {
  subHeading: string;
  mainHeading: string;
  author: string;
  date: string;
  contentImage: string;
  metaDescription: string;
  url: string;
  bodyContent: {
    type: string,
    introduction: string,
    heading1: string,
    heading1Point1: string,
    heading1Point1Content1: string,
    heading1Point1Content2: string,
    heading1Point2: string,
    heading1Point2Content1: string,
    heading1Point2Content2: string,
    heading1Point3: string,
    heading1Point3Content1: string,
    heading1Point3Content2: string,
    heading1Point4: string,
    heading1Point4Content1: string,
    heading1Point4Content2: string,
    heading1Point5: string,
    heading1Point5Content1: string,
    heading1Point5Content2: string,
    heading1Point6: string,
    heading1Point6Content1: string,
    heading1Point6Content2: string,
    content1Paragraph2: string,
    heading2: string,
    content1Paragraph1: string,
    content2Paragraph1: string,
    heading2Point1: string,
    heading2Point1Content1: string,
    heading2Point1Content2: string,
    heading2Point2Content1: string,
    heading2Point2Content2: string,
    heading2Point2Content3: string,
    heading2Point3Content1: string,
    heading2Point3Content2: string,
    heading2Point2: string,
    heading2Point4: string,
    heading2Point4Content1: string,
    heading2Point4Content2: string,
    heading2Point5: string,
    heading2Point5Content1: string,
    heading2Point5Content2: string,

    heading2Point1Content: string,
    heading2Point2Content: string,
    heading3: string,
    heading2Point3: string,
    heading2Point3Content: string,
    heading3Point1: string,
    heading3Point1Content: string,
    heading3Point2: string,
    heading3Point2Content: string,
    heading3Point3: string,
    heading3Point3Content: string,
    heading3Point4: string,
    heading3Point4Content: string,
    heading3Point5: string,
    heading3Point5Content: string,
    content3Paragraph1: string,
    content3Paragraph2: string,
    heading4: string,
    heading4Point1: string,
    heading4Point2: string,
    heading4Point3: string,
    heading4Point4: string,
    heading4Point4Content: string,

    content4Paragraph1: string,
    content4Paragraph2: string,
    heading5: string,
    content5Paragraph1: string,
    content5Paragraph2: string,
    heading5Point5: string,
    heading5Point5Content: string,
    point1Content: string,
    point2Content: string,
    point3Content: string,
    point4Content: string,

    pros1: string,
    content1pros1: string,
    content1pros2: string,
    content1pros3: string,
    content2pros1: string,
    content2pros2: string,
    content2pros3: string,

    cons1: string,
    content1cons1: string,
    content1cons2: string,
    content2cons1: string,
    content2cons2: string,

    faqs: string,
    question1: string,
    answer1: string,
    question2: string,
    answer2: string,
    question3: string,
    answer3: string,
    question4: string,
    answer4: string,
    question5: string,
    answer5: string,
    conclusion: string,
    conclusionContent: string,
    boldParagraph: string,
    regularParagraph: string,
    italicParagraph: string,
    regularParagraphtype: string,
    bodyHeading: string,
    bodyParagraph: string,
    imageTitle: string,
    imageAlt: string,
  };
}



const BlogSingle = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const props = useLocation().state as BlogData;

  switch (props.bodyContent.type) {
    case "trend-2023":
      return (
        <>
          <Helmet>
            <title>{props.mainHeading}</title>
            <meta name="description" content={props.metaDescription} />
            <link rel="canonical" href={`/blogs/${props.url}`} />
          </Helmet>
          <Container maxWidth="xl" sx={{ marginTop: "8rem", bgcolor: "#fff" }}>
            <Container maxWidth="md">
              <Box>
                <Typography variant="h6" sx={{ fontFamily: "Inter" }}>
                  {props.subHeading}
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant="h1"
                  sx={{
                    width: {
                      xl: "70%",
                      lg: "70%",
                      md: "70%",
                      sm: "100%",
                      xs: "100%",
                    },
                    fontFamily: "Noto Serif",
                    fontSize: '2rem'
                  }}
                >
                  {props.mainHeading}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  marginTop: "2rem",
                  width: "100%",
                }}
              >
                <img src={Vector}
                  width="20px"
                  height="20px"
                  title="calendar"
                  loading="lazy"
                  alt="Vector" />
                <Typography sx={{ marginLeft: "1rem", marginRight: "2rem" }}>
                  {props.date}
                </Typography>
                <img src={Rawan}
                  width="20px"
                  height="20px"
                  title="profile"
                  loading="lazy" alt="Rawan" />
                <Typography sx={{ marginLeft: "1rem" }}>
                  {props.author}
                </Typography>
              </Box>
              <Box
                sx={{
                  paddingTop: "3rem",
                  display: "flex",
                  justifyContent: {
                    xl: "flex-end",
                    lg: "flex-end",
                    md: "flex-end",
                    sm: "center",
                    xs: "center",
                  },
                }}
              >
                <img
                  src={props.contentImage}
                  alt={props.bodyContent.imageAlt}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    borderRadius: ".5rem",
                  }}
                  width="100%"
                  height="100%"
                  title={props.bodyContent.imageTitle}
                  loading="lazy"
                  className={classes.imgMain}
                />
              </Box>
              <Box
                className={classes.ImageInside}
                sx={{
                  paddingTop: "3rem",
                  display: "flex",
                }}
              >
                <Box className={`${classes.subBox} ${classes.socialHandle}`}>
                  <Box>
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: "1rem",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontWeight: "bold",
                        paddingBottom: "1rem",
                      }}
                    >
                      Share this article
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Link to={'//www.facebook.com/sthlmtechpartner/'} target={'_blank'}>
                      <img
                        src={Facebook}
                        width="9px"
                        height="15.5px"
                        alt="facebookIcon"
                        title="facebookIcon"
                        loading="lazy"
                      />
                    </Link>
                    <Link to={'//mobile.twitter.com/sthlmtechprtnr'} target={'_blank'}>
                      <img src={Twitter} width="11.5px"
                        height="10.5px"
                        loading="lazy"
                        title="twitterIcon"

                        alt="twitterIcon" />
                    </Link>
                    <Link to={'//www.linkedin.com/company/sthlm-tech-partner'} target={'_blank'}>
                      <img src={LinkedIn}
                        width="12px"
                        height="12px"
                        loading="lazy"
                        title="linkedinIcon"

                        alt="linkedinIcon" />
                    </Link>
                    <Link to={'//www.instagram.com/sthlmtechpartner.official/?hl=en'} target={'_blank'}>
                      <img
                        src={Instagram}
                        width="11px"
                        height="11px"
                        loading="lazy"
                        alt="instagramIcon"
                        title="instagramIcon"

                      />
                    </Link>
                  </Box>
                </Box>
                <Box
                  sx={{
                    width: {
                      xl: "80%",
                      lg: "80%",
                      md: "80%",
                      sm: "100%",
                      xs: "100%",
                    },
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: "bold",
                      fontSize: "1rem",
                      paddingLeft: {
                        xl: "2rem",
                        lg: "2rem",
                        md: "2rem",
                        sm: "0",
                        xs: "0",
                      },
                    }}
                  >
                    {props.bodyContent.introduction}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  paddingLeft: {
                    xl: "20%",
                    lg: "20%",
                    md: "20%",
                    sm: "0",
                    xs: "0",
                  },
                  width: "95%",
                }}
              >
                <Typography
                  variant="h5"
                  sx={{ fontFamily: "Inter", marginTop: "2rem" }}
                >
                  {props.bodyContent.heading1Point1}
                </Typography>
                <Typography
                  variant="body1"
                  style={{
                    marginTop: "1.5rem",
                    marginBottom: "1rem",
                  }}
                >
                  {props.bodyContent.heading1Point1Content1}
                </Typography>
                <Typography
                  variant="body1"
                  style={{
                    marginTop: "1.5rem",
                    marginBottom: "1rem",
                  }}
                >
                  {props.bodyContent.heading1Point1Content2}
                </Typography>

                <Typography
                  variant="h5"
                  sx={{ fontFamily: "Inter", marginTop: "2rem" }}
                >
                  {props.bodyContent.heading1Point2}
                </Typography>
                <Typography
                  variant="body1"
                  style={{
                    marginTop: "1.5rem",
                    marginBottom: "1rem",
                  }}
                >
                  {props.bodyContent.heading1Point2Content1}
                </Typography>
                <Typography
                  variant="body1"
                  style={{
                    marginTop: "1.5rem",
                    marginBottom: "1rem",
                  }}
                >
                  {props.bodyContent.heading1Point2Content2}
                </Typography>

                <Typography
                  variant="h5"
                  sx={{ fontFamily: "Inter", marginTop: "2rem" }}
                >
                  {props.bodyContent.heading1Point3}
                </Typography>
                <Typography
                  variant="body1"
                  style={{
                    marginTop: "1.5rem",
                    marginBottom: "1rem",
                  }}
                >
                  {props.bodyContent.heading1Point3Content2}
                </Typography>
                <Typography
                  variant="body1"
                  style={{
                    marginTop: "1.5rem",
                    marginBottom: "1rem",
                  }}
                >
                  {props.bodyContent.heading1Point3Content1}
                </Typography>
                <Typography
                  variant="h5"
                  sx={{ fontFamily: "Inter", marginTop: "2rem" }}
                >
                  {props.bodyContent.heading1Point4}
                </Typography>
                <Typography
                  variant="body1"
                  style={{
                    marginTop: "1.5rem",
                    marginBottom: "1rem",
                  }}
                >
                  {props.bodyContent.heading1Point4Content1}
                </Typography>
                <Typography
                  variant="body1"
                  style={{
                    marginTop: "1.5rem",
                    marginBottom: "1rem",
                  }}
                >
                  {props.bodyContent.heading1Point4Content2}
                </Typography>
                <Typography
                  variant="h5"
                  sx={{ fontFamily: "Inter", marginTop: "2rem" }}
                >
                  {props.bodyContent.heading1Point5}
                </Typography>
                <Typography
                  variant="body1"
                  style={{
                    marginTop: "1.5rem",
                    marginBottom: "1rem",
                  }}
                >
                  {props.bodyContent.heading1Point5Content1}
                </Typography>
                <Typography
                  variant="body1"
                  style={{
                    marginTop: "1.5rem",
                    marginBottom: "1rem",
                  }}
                >
                  {props.bodyContent.heading1Point5Content2}
                </Typography>
                <Typography
                  variant="h5"
                  sx={{ fontFamily: "Inter", marginTop: "2rem" }}
                >
                  {props.bodyContent.heading1Point6}
                </Typography>
                <Typography
                  variant="body1"
                  style={{
                    marginTop: "1.5rem",
                    marginBottom: "1rem",
                  }}
                >
                  {props.bodyContent.heading1Point6Content1}
                </Typography>
                <Typography
                  variant="body1"
                  style={{
                    marginTop: "1.5rem",
                    marginBottom: "1rem",
                  }}
                >
                  {props.bodyContent.heading1Point6Content2}
                </Typography>
                <Typography
                  variant="h3"
                  style={{
                    fontWeight: "500",
                    marginTop: "1.5rem",
                    marginBottom: "1rem",
                    fontSize: '2rem'
                  }}
                >
                  {props.bodyContent.faqs}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontWeight: "500", marginTop: "1rem" }}
                >
                  {props.bodyContent.question1}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontWeight: "500", marginTop: "1rem" }}
                >
                  {props.bodyContent.answer1}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontWeight: "500", marginTop: "1rem" }}
                >
                  {props.bodyContent.question2}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontWeight: "500", marginTop: "1rem" }}
                >
                  {props.bodyContent.answer2}
                </Typography>
                <Typography
                  variant="h3"
                  style={{
                    fontWeight: "500",
                    marginTop: "1.5rem",
                    marginBottom: "1rem",
                    fontSize: '2rem'
                  }}
                >
                  {props.bodyContent.conclusion}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontWeight: "500", marginTop: "1rem" }}
                >
                  {props.bodyContent.conclusionContent}
                </Typography>
              </Box>
              <Box
                style={{ paddingTop: "1rem" }}
                // className={`${classes.subBox}`}
                className={classes.socialHide}
              >
                <Box>
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: "1rem",
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      fontWeight: "bold",
                      paddingBottom: "1rem",
                    }}
                  >
                    Share this article
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    // width: "60%",
                  }}
                >
                  <Link to={'//www.facebook.com/sthlmtechpartner/'} target={'_blank'}>
                    <img
                      src={Facebook}
                      style={{ marginRight: ".5rem" }}
                      width="9px"
                      height="15.5px"
                      alt="facebookIcon"
                      title="facebookIcon"

                      loading="lazy"
                    />
                  </Link>
                  <Link to={'//mobile.twitter.com/sthlmtechprtnr'} target={'_blank'}>
                    <img
                      src={Twitter}
                      width="11.5px"
                      height="10.5px"
                      loading="lazy"
                      alt="twitterIcon"
                      title="twitterIcon"

                      style={{ marginRight: ".5rem" }}
                    />
                  </Link>
                  <Link to={'//www.linkedin.com/company/sthlm-tech-partner'} target={'_blank'}>
                    <img
                      src={LinkedIn}
                      width="12px"
                      height="12px"
                      loading="lazy"
                      alt="linkedinIcon"
                      title="linkedinIcon"

                      style={{ marginRight: ".5rem" }}
                    />
                  </Link>
                  <Link to={'//www.instagram.com/sthlmtechpartner.official/?hl=en'} target={'_blank'}>
                    <img
                      src={Instagram}
                      width="11px"
                      height="11px"
                      loading="lazy"
                      alt="instagramIcon"
                      title="instagramIcon"
                      style={{ marginRight: ".5rem" }}
                    />
                  </Link>
                </Box>
              </Box>
              <Box
                className={classes.insideBoxes}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  className={classes.insideBoxes}
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  {blogCardContent.map((item, index) => {
                    return (

                      <Box
                        sx={{
                          marginTop: "2rem",
                          marginBottom: {
                            xl: "3rem",
                            lg: "3rem",
                            md: "3rem",
                            sm: "0",
                            xs: "0",
                          },
                        }}
                        key={index}
                      >
                        <BlogCard
                          sx={{
                            fontSize: "1rem",
                            color: "#fff",
                            fontWeight: 540,
                            lineHeight: "1.5rem",
                          }}
                          title=""
                          width="17rem"
                          height="22rem"
                          description={item.description}
                          backgroundImage={item.backgroundImage}
                          arrowButton={<ArrowButton />}
                          onClick={() =>
                            navigate(`/blogs/${props.url}`, {
                              state: item.content,
                            })
                          }
                        />
                      </Box>

                    );
                  })}
                </Box>
              </Box>
            </Container>
          </Container >
          <Footer />
        </>
      );
    case "native-hybrid":
      return (
        <>
          <Helmet>
            <title>{props.mainHeading}</title>
            <meta name="description" content={props.metaDescription} />
            <link rel="canonical" href={`/blogs/${props.url}`} />
          </Helmet>
          <Container maxWidth="xl" sx={{ marginTop: "8rem", bgcolor: "#fff" }}>
            <Container maxWidth="md">
              <Box>
                <Typography variant="h6" sx={{ fontFamily: "Inter" }}>
                  {props.subHeading}
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant="h1"
                  sx={{
                    width: {
                      xl: "70%",
                      lg: "70%",
                      md: "70%",
                      sm: "100%",
                      xs: "100%",
                    },
                    fontFamily: "Noto Serif",
                    fontSize: '2rem'
                  }}
                >
                  {props.mainHeading}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  marginTop: "2rem",
                  width: "100%",
                }}
              >
                <img src={Vector}
                  width="20px"
                  height="20px"
                  title="calendar"
                  loading="lazy"
                  alt="Vector" />
                <Typography sx={{ marginLeft: "1rem", marginRight: "2rem" }}>
                  {props.date}
                </Typography>
                <img src={Rawan}
                  width="20px"
                  height="20px"
                  title="profile"
                  loading="lazy" alt="Rawan" />
                <Typography sx={{ marginLeft: "1rem" }}>
                  {props.author}
                </Typography>
              </Box>
              <Box
                sx={{
                  paddingTop: "3rem",
                  display: "flex",
                  justifyContent: {
                    xl: "flex-end",
                    lg: "flex-end",
                    md: "flex-end",
                    sm: "center",
                    xs: "center",
                  },
                }}
              >
                <img
                  src={props.contentImage}
                  alt={props.bodyContent.imageAlt}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    borderRadius: ".5rem",
                  }}
                  width="100%"
                  height="100%"
                  title={props.bodyContent.imageTitle}
                  loading="lazy"
                  className={classes.imgMain}
                />
              </Box>
              <Box
                className={classes.ImageInside}
                sx={{
                  paddingTop: "3rem",
                  display: "flex",
                }}
              >
                <Box className={`${classes.subBox} ${classes.socialHandle}`}>
                  <Box>
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: "1rem",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontWeight: "bold",
                        paddingBottom: "1rem",
                      }}
                    >
                      Share this article
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Link to={'//www.facebook.com/sthlmtechpartner/'} target={'_blank'}>
                      <img
                        src={Facebook}
                        width="9px"
                        height="15.5px"
                        alt="facebookIcon"
                        title="facebookIcon"
                        loading="lazy"
                      />
                    </Link>
                    <Link to={'//mobile.twitter.com/sthlmtechprtnr'} target={'_blank'}>
                      <img src={Twitter} width="11.5px"
                        height="10.5px"
                        loading="lazy"
                        title="twitterIcon"

                        alt="twitterIcon" />
                    </Link>
                    <Link to={'//www.linkedin.com/company/sthlm-tech-partner'} target={'_blank'}>
                      <img src={LinkedIn}
                        width="12px"
                        height="12px"
                        loading="lazy"
                        title="linkedinIcon"

                        alt="linkedinIcon" />
                    </Link>
                    <Link to={'//www.instagram.com/sthlmtechpartner.official/?hl=en'} target={'_blank'}>
                      <img
                        src={Instagram}
                        width="11px"
                        height="11px"
                        loading="lazy"
                        alt="instagramIcon"
                        title="instagramIcon"

                      />
                    </Link>
                  </Box>
                </Box>
                <Box
                  sx={{
                    width: {
                      xl: "80%",
                      lg: "80%",
                      md: "80%",
                      sm: "100%",
                      xs: "100%",
                    },
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: "bold",
                      fontSize: "1rem",
                      paddingLeft: {
                        xl: "2rem",
                        lg: "2rem",
                        md: "2rem",
                        sm: "0",
                        xs: "0",
                      },
                    }}
                  >
                    {props.bodyContent.introduction}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  paddingLeft: {
                    xl: "20%",
                    lg: "20%",
                    md: "20%",
                    sm: "0",
                    xs: "0",
                  },
                  width: "95%",
                }}
              >
                <Typography
                  variant="h5"
                  sx={{ fontFamily: "Inter", marginTop: "2rem" }}
                >
                  {props.bodyContent.heading1}
                </Typography>
                <Typography
                  variant="body1"
                  style={{
                    marginTop: "1.5rem",
                    marginBottom: "1rem",
                  }}
                >
                  {props.bodyContent.content1Paragraph1}
                </Typography>
                <Typography
                  variant="h6"
                  style={{
                    marginTop: "1.5rem",
                    marginBottom: "1rem",
                  }}
                >
                  {props.bodyContent.pros1}
                </Typography>
                <Typography
                  variant="body1"
                  style={{
                    marginTop: "1.5rem",
                    marginBottom: "1rem",
                  }}
                >
                  {props.bodyContent.content1pros1}
                </Typography>
                <Typography
                  variant="body1"
                  style={{
                    marginTop: "1.5rem",
                    marginBottom: "1rem",
                  }}
                >
                  {props.bodyContent.content1pros2}
                </Typography>
                <Typography
                  variant="body1"
                  style={{
                    marginTop: "1.5rem",
                    marginBottom: "1rem",
                  }}
                >
                  {props.bodyContent.content1pros3}
                </Typography>
                <Typography
                  variant="h6"
                  sx={{ fontFamily: "Inter", marginTop: "2rem" }}
                >
                  {props.bodyContent.cons1}
                </Typography>
                <Typography
                  variant="body1"
                  style={{
                    marginTop: "1.5rem",
                    marginBottom: "1rem",
                  }}
                >
                  {props.bodyContent.content1cons1}
                </Typography>
                <Typography
                  variant="body1"
                  style={{
                    marginTop: "1.5rem",
                    marginBottom: "1rem",
                  }}
                >
                  {props.bodyContent.content1cons2}
                </Typography>

                <Typography
                  variant="h5"
                  sx={{ fontFamily: "Inter", marginTop: "2rem" }}
                >
                  {props.bodyContent.heading2}
                </Typography>
                <Typography
                  variant="body1"
                  style={{
                    marginTop: "1.5rem",
                    marginBottom: "1rem",
                  }}
                >
                  {props.bodyContent.content2Paragraph1}
                </Typography>
                <Typography
                  variant="h6"
                  style={{
                    marginTop: "1.5rem",
                    marginBottom: "1rem",
                  }}
                >
                  {props.bodyContent.pros1}
                </Typography>
                <Typography
                  variant="body1"
                  style={{
                    marginTop: "1.5rem",
                    marginBottom: "1rem",
                  }}
                >
                  {props.bodyContent.content2pros1}
                </Typography>
                <Typography
                  variant="body1"
                  style={{
                    marginTop: "1.5rem",
                    marginBottom: "1rem",
                  }}
                >
                  {props.bodyContent.content2pros2}
                </Typography>
                <Typography
                  variant="body1"
                  style={{
                    marginTop: "1.5rem",
                    marginBottom: "1rem",
                  }}
                >
                  {props.bodyContent.content2pros3}
                </Typography>
                <Typography
                  variant="h6"
                  sx={{ fontFamily: "Inter", marginTop: "2rem" }}
                >
                  {props.bodyContent.cons1}
                </Typography>
                <Typography
                  variant="body1"
                  style={{
                    marginTop: "1.5rem",
                    marginBottom: "1rem",
                  }}
                >
                  {props.bodyContent.content2cons1}
                </Typography>
                <Typography
                  variant="body1"
                  style={{
                    marginTop: "1.5rem",
                    marginBottom: "1rem",
                  }}
                >
                  {props.bodyContent.content2cons2}
                </Typography>
                <Typography
                  variant="h3"
                  style={{
                    fontWeight: "500",
                    marginTop: "1.5rem",
                    marginBottom: "1rem",
                    fontSize: '2rem'
                  }}
                >
                  {props.bodyContent.faqs}
                </Typography>

                <Typography
                  variant="body2"
                  sx={{ fontWeight: "500", marginTop: "1rem" }}
                >
                  {props.bodyContent.question1}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontWeight: "500", marginTop: "1rem" }}
                >
                  {props.bodyContent.answer1}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontWeight: "500", marginTop: "1rem" }}
                >
                  {props.bodyContent.question2}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontWeight: "500", marginTop: "1rem" }}
                >
                  {props.bodyContent.answer2}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontWeight: "500", marginTop: "1rem" }}
                >
                  {props.bodyContent.question3}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontWeight: "500", marginTop: "1rem" }}
                >
                  {props.bodyContent.answer3}
                </Typography>
                <Typography
                  variant="h3"
                  style={{
                    fontWeight: "500",
                    marginTop: "1.5rem",
                    marginBottom: "1rem",
                    fontSize: '2rem'
                  }}
                >
                  {props.bodyContent.conclusion}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontWeight: "500", marginTop: "1rem" }}
                >
                  {props.bodyContent.conclusionContent}
                </Typography>
              </Box>
              <Box
                style={{ paddingTop: "1rem" }}
                // className={`${classes.subBox}`}
                className={classes.socialHide}
              >
                <Box>
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: "1rem",
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      fontWeight: "bold",
                      paddingBottom: "1rem",
                    }}
                  >
                    Share this article
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    // width: "60%",
                  }}
                >
                  <Link to={'//www.facebook.com/sthlmtechpartner/'} target={'_blank'}>
                    <img
                      src={Facebook}
                      style={{ marginRight: ".5rem" }}
                      width="9px"
                      height="15.5px"
                      alt="facebookIcon"
                      title="facebookIcon"

                      loading="lazy"
                    />
                  </Link>
                  <Link to={'//mobile.twitter.com/sthlmtechprtnr'} target={'_blank'}>
                    <img
                      src={Twitter}
                      width="11.5px"
                      height="10.5px"
                      loading="lazy"
                      alt="twitterIcon"
                      title="twitterIcon"

                      style={{ marginRight: ".5rem" }}
                    />
                  </Link>
                  <Link to={'//www.linkedin.com/company/sthlm-tech-partner'} target={'_blank'}>
                    <img
                      src={LinkedIn}
                      width="12px"
                      height="12px"
                      loading="lazy"
                      alt="linkedinIcon"
                      title="linkedinIcon"

                      style={{ marginRight: ".5rem" }}
                    />
                  </Link>
                  <Link to={'//www.instagram.com/sthlmtechpartner.official/?hl=en'} target={'_blank'}>
                    <img
                      src={Instagram}
                      width="11px"
                      height="11px"
                      loading="lazy"
                      alt="instagramIcon"
                      title="instagramIcon"
                      style={{ marginRight: ".5rem" }}
                    />
                  </Link>
                </Box>
              </Box>
              <Box
                className={classes.insideBoxes}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  className={classes.insideBoxes}
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  {blogCardContent.map((item, index) => {
                    return (

                      <Box
                        sx={{
                          marginTop: "2rem",
                          marginBottom: {
                            xl: "3rem",
                            lg: "3rem",
                            md: "3rem",
                            sm: "0",
                            xs: "0",
                          },
                        }}
                        key={index}
                      >
                        <BlogCard
                          sx={{
                            fontSize: "1rem",
                            color: "#fff",
                            fontWeight: 540,
                            lineHeight: "1.5rem",
                          }}
                          title=""
                          width="17rem"
                          height="22rem"
                          description={item.description}
                          backgroundImage={item.backgroundImage}
                          arrowButton={<ArrowButton />}
                          onClick={() =>
                            navigate(`/blogs/${props.url}`, {
                              state: item.content,
                            })
                          }
                        />
                      </Box>

                    );
                  })}
                </Box>
              </Box>
            </Container>
          </Container >
          <Footer />
        </>
      );
    case "mobile-app-ux":
      return (
        <>
          <Helmet>
            <title>{props.mainHeading}</title>
            <meta name="description" content={props.metaDescription} />
            <link rel="canonical" href={`/blogs/${props.url}`} />
          </Helmet>
          <Container maxWidth="xl" sx={{ marginTop: "8rem", bgcolor: "#fff" }}>
            <Container maxWidth="md">
              <Box>
                <Typography variant="h6" sx={{ fontFamily: "Inter" }}>
                  {props.subHeading}
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant="h1"
                  sx={{
                    width: {
                      xl: "70%",
                      lg: "70%",
                      md: "70%",
                      sm: "100%",
                      xs: "100%",
                    },
                    fontFamily: "Noto Serif",
                    fontSize: '2rem'
                  }}
                >
                  {props.mainHeading}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  marginTop: "2rem",
                  width: "100%",
                }}
              >
                <img src={Vector}
                  width="20px"
                  height="20px"
                  title="calendar"
                  loading="lazy"
                  alt="Vector" />
                <Typography sx={{ marginLeft: "1rem", marginRight: "2rem" }}>
                  {props.date}
                </Typography>
                <img src={Rawan}
                  width="20px"
                  height="20px"
                  title="profile"
                  loading="lazy" alt="Rawan" />
                <Typography sx={{ marginLeft: "1rem" }}>
                  {props.author}
                </Typography>
              </Box>
              <Box
                sx={{
                  paddingTop: "3rem",
                  display: "flex",
                  justifyContent: {
                    xl: "flex-end",
                    lg: "flex-end",
                    md: "flex-end",
                    sm: "center",
                    xs: "center",
                  },
                }}
              >
                <img
                  src={props.contentImage}
                  alt={props.bodyContent.imageAlt}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    borderRadius: ".5rem",
                  }}
                  width="100%"
                  height="100%"
                  title={props.bodyContent.imageTitle}
                  loading="lazy"
                  className={classes.imgMain}
                />
              </Box>
              <Box
                className={classes.ImageInside}
                sx={{
                  paddingTop: "3rem",
                  display: "flex",
                }}
              >
                <Box className={`${classes.subBox} ${classes.socialHandle}`}>
                  <Box>
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: "1rem",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontWeight: "bold",
                        paddingBottom: "1rem",
                      }}
                    >
                      Share this article
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Link to={'//www.facebook.com/sthlmtechpartner/'} target={'_blank'}>
                      <img
                        src={Facebook}
                        width="9px"
                        height="15.5px"
                        alt="facebookIcon"
                        title="facebookIcon"
                        loading="lazy"
                      />
                    </Link>
                    <Link to={'//mobile.twitter.com/sthlmtechprtnr'} target={'_blank'}>
                      <img src={Twitter} width="11.5px"
                        height="10.5px"
                        loading="lazy"
                        title="twitterIcon"

                        alt="twitterIcon" />
                    </Link>
                    <Link to={'//www.linkedin.com/company/sthlm-tech-partner'} target={'_blank'}>
                      <img src={LinkedIn}
                        width="12px"
                        height="12px"
                        loading="lazy"
                        title="linkedinIcon"

                        alt="linkedinIcon" />
                    </Link>
                    <Link to={'//www.instagram.com/sthlmtechpartner.official/?hl=en'} target={'_blank'}>
                      <img
                        src={Instagram}
                        width="11px"
                        height="11px"
                        loading="lazy"
                        alt="instagramIcon"
                        title="instagramIcon"

                      />
                    </Link>
                  </Box>
                </Box>
                <Box
                  sx={{
                    width: {
                      xl: "80%",
                      lg: "80%",
                      md: "80%",
                      sm: "100%",
                      xs: "100%",
                    },
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: "bold",
                      fontSize: "1rem",
                      paddingLeft: {
                        xl: "2rem",
                        lg: "2rem",
                        md: "2rem",
                        sm: "0",
                        xs: "0",
                      },
                    }}
                  >
                    {props.bodyContent.introduction}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  paddingLeft: {
                    xl: "20%",
                    lg: "20%",
                    md: "20%",
                    sm: "0",
                    xs: "0",
                  },
                  width: "95%",
                }}
              >
                <Typography
                  variant="h5"
                  sx={{ fontFamily: "Inter", marginTop: "2rem" }}
                >
                  {props.bodyContent.heading1}
                </Typography>
                <Typography
                  variant="body1"
                  style={{
                    marginTop: "1.5rem",
                    marginBottom: "1rem",
                  }}
                >
                  {props.bodyContent.content1Paragraph1}
                </Typography>
                <Typography
                  variant="body1"
                  style={{
                    marginTop: "1.5rem",
                    marginBottom: "1rem",
                  }}
                >
                  {props.bodyContent.content1Paragraph2}
                </Typography>
                <Typography
                  variant="h5"
                  sx={{ fontFamily: "Inter", marginTop: "2rem" }}
                >
                  {props.bodyContent.heading2}
                </Typography>
                <Typography
                  variant="body1"
                  style={{
                    marginTop: "1.5rem",
                    marginBottom: "1rem",
                  }}
                >
                  {props.bodyContent.content2Paragraph1}
                </Typography>
                <Typography
                  variant="h5"
                  sx={{ fontFamily: "Inter", marginTop: "2rem" }}
                >
                  {props.bodyContent.heading3}
                </Typography>
                <Typography
                  variant="body1"
                  style={{
                    marginTop: "1.5rem",
                    marginBottom: "1rem",
                  }}
                >
                  {props.bodyContent.content3Paragraph1}
                </Typography>
                <Typography
                  variant="h5"
                  sx={{ fontFamily: "Inter", marginTop: "2rem" }}
                >
                  {props.bodyContent.heading4}
                </Typography>
                <Typography
                  variant="body1"
                  style={{
                    marginTop: "1.5rem",
                    marginBottom: "1rem",
                  }}
                >
                  {props.bodyContent.content4Paragraph1}
                </Typography>
                <Typography
                  variant="body1"
                  style={{
                    marginTop: "1.5rem",
                    marginBottom: "1rem",
                  }}
                >
                  {props.bodyContent.content4Paragraph2}
                </Typography>
                <Typography
                  variant="h6"
                  style={{
                    marginTop: "1.5rem",
                    marginBottom: "1rem",
                  }}
                >
                  {props.bodyContent.heading4Point1}
                </Typography>
                <Typography
                  variant="body1"
                  style={{
                    marginTop: "1.5rem",
                    marginBottom: "1rem",
                  }}
                >
                  {props.bodyContent.point1Content}
                </Typography>
                <Typography
                  variant="h6"
                  style={{
                    marginTop: "1.5rem",
                    marginBottom: "1rem",
                  }}
                >
                  {props.bodyContent.heading4Point2}
                </Typography>
                <Typography
                  variant="body1"
                  style={{
                    marginTop: "1.5rem",
                    marginBottom: "1rem",
                  }}
                >
                  {props.bodyContent.point2Content}
                </Typography>
                <Typography
                  variant="h6"
                  style={{
                    marginTop: "1.5rem",
                    marginBottom: "1rem",
                  }}
                >
                  {props.bodyContent.heading4Point3}
                </Typography>
                <Typography
                  variant="body1"
                  style={{
                    marginTop: "1.5rem",
                    marginBottom: "1rem",
                  }}
                >
                  {props.bodyContent.point3Content}
                </Typography>
                <Typography
                  variant="h6"
                  style={{
                    marginTop: "1.5rem",
                    marginBottom: "1rem",
                  }}
                >
                  {props.bodyContent.heading4Point4}
                </Typography>
                <Typography
                  variant="body1"
                  style={{
                    marginTop: "1.5rem",
                    marginBottom: "1rem",
                  }}
                >
                  {props.bodyContent.point4Content}
                </Typography>
                <Typography
                  variant="h3"
                  style={{
                    fontWeight: "500",
                    marginTop: "1.5rem",
                    marginBottom: "1rem",
                    fontSize: '2rem'
                  }}
                >
                  {props.bodyContent.heading5}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontWeight: "500", marginTop: "1rem" }}
                >
                  {props.bodyContent.content5Paragraph1}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontWeight: "500", marginTop: "1rem" }}
                >
                  {props.bodyContent.content5Paragraph2}
                </Typography>
              </Box>
              <Box
                style={{ paddingTop: "1rem" }}
                // className={`${classes.subBox}`}
                className={classes.socialHide}
              >
                <Box>
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: "1rem",
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      fontWeight: "bold",
                      paddingBottom: "1rem",
                    }}
                  >
                    Share this article
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    // width: "60%",
                  }}
                >
                  <Link to={'//www.facebook.com/sthlmtechpartner/'} target={'_blank'}>
                    <img
                      src={Facebook}
                      style={{ marginRight: ".5rem" }}
                      width="9px"
                      height="15.5px"
                      alt="facebookIcon"
                      title="facebookIcon"

                      loading="lazy"
                    />
                  </Link>
                  <Link to={'//mobile.twitter.com/sthlmtechprtnr'} target={'_blank'}>
                    <img
                      src={Twitter}
                      width="11.5px"
                      height="10.5px"
                      loading="lazy"
                      alt="twitterIcon"
                      title="twitterIcon"

                      style={{ marginRight: ".5rem" }}
                    />
                  </Link>
                  <Link to={'//www.linkedin.com/company/sthlm-tech-partner'} target={'_blank'}>
                    <img
                      src={LinkedIn}
                      width="12px"
                      height="12px"
                      loading="lazy"
                      alt="linkedinIcon"
                      title="linkedinIcon"

                      style={{ marginRight: ".5rem" }}
                    />
                  </Link>
                  <Link to={'//www.instagram.com/sthlmtechpartner.official/?hl=en'} target={'_blank'}>
                    <img
                      src={Instagram}
                      width="11px"
                      height="11px"
                      loading="lazy"
                      alt="instagramIcon"
                      title="instagramIcon"
                      style={{ marginRight: ".5rem" }}
                    />
                  </Link>
                </Box>
              </Box>
              <Box
                className={classes.insideBoxes}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  className={classes.insideBoxes}
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  {blogCardContent.map((item, index) => {
                    return (

                      <Box
                        sx={{
                          marginTop: "2rem",
                          marginBottom: {
                            xl: "3rem",
                            lg: "3rem",
                            md: "3rem",
                            sm: "0",
                            xs: "0",
                          },
                        }}
                        key={index}
                      >
                        <BlogCard
                          sx={{
                            fontSize: "1rem",
                            color: "#fff",
                            fontWeight: 540,
                            lineHeight: "1.5rem",
                          }}
                          title=""
                          width="17rem"
                          height="22rem"
                          description={item.description}
                          backgroundImage={item.backgroundImage}
                          arrowButton={<ArrowButton />}
                          onClick={() =>
                            navigate(`/blogs/${props.url}`, {
                              state: item.content,
                            })
                          }
                        />
                      </Box>

                    );
                  })}
                </Box>
              </Box>
            </Container>
          </Container >
          <Footer />
        </>
      );
    case "ai-mobile-app-dev":
      return (
        <>
          <Helmet>
            <title>{props.mainHeading}</title>
            <meta name="description" content={props.metaDescription} />
            <link rel="canonical" href={`/blogs/${props.url}`} />
          </Helmet>
          <Container maxWidth="xl" sx={{ marginTop: "8rem", bgcolor: "#fff" }}>
            <Container maxWidth="md">
              <Box>
                <Typography variant="h6" sx={{ fontFamily: "Inter" }}>
                  {props.subHeading}
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant="h1"
                  sx={{
                    width: {
                      xl: "70%",
                      lg: "70%",
                      md: "70%",
                      sm: "100%",
                      xs: "100%",
                    },
                    fontFamily: "Noto Serif",
                    fontSize: '2rem'
                  }}
                >
                  {props.mainHeading}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  marginTop: "2rem",
                  width: "100%",
                }}
              >
                <img src={Vector}
                  width="20px"
                  height="20px"
                  title="calendar"
                  loading="lazy"
                  alt="Vector" />
                <Typography sx={{ marginLeft: "1rem", marginRight: "2rem" }}>
                  {props.date}
                </Typography>
                <img src={Rawan}
                  width="20px"
                  height="20px"
                  title="profile"
                  loading="lazy" alt="Rawan" />
                <Typography sx={{ marginLeft: "1rem" }}>
                  {props.author}
                </Typography>
              </Box>
              <Box
                sx={{
                  paddingTop: "3rem",
                  display: "flex",
                  justifyContent: {
                    xl: "flex-end",
                    lg: "flex-end",
                    md: "flex-end",
                    sm: "center",
                    xs: "center",
                  },
                }}
              >
                <img
                  src={props.contentImage}
                  alt={props.bodyContent.imageAlt}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    borderRadius: ".5rem",
                  }}
                  width="100%"
                  height="100%"
                  title={props.bodyContent.imageTitle}
                  loading="lazy"
                  className={classes.imgMain}
                />
              </Box>
              <Box
                className={classes.ImageInside}
                sx={{
                  paddingTop: "3rem",
                  display: "flex",
                }}
              >
                <Box className={`${classes.subBox} ${classes.socialHandle}`}>
                  <Box>
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: "1rem",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontWeight: "bold",
                        paddingBottom: "1rem",
                      }}
                    >
                      Share this article
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Link to={'//www.facebook.com/sthlmtechpartner/'} target={'_blank'}>
                      <img
                        src={Facebook}
                        width="9px"
                        height="15.5px"
                        alt="facebookIcon"
                        title="facebookIcon"
                        loading="lazy"
                      />
                    </Link>
                    <Link to={'//mobile.twitter.com/sthlmtechprtnr'} target={'_blank'}>
                      <img src={Twitter} width="11.5px"
                        height="10.5px"
                        loading="lazy"
                        title="twitterIcon"

                        alt="twitterIcon" />
                    </Link>
                    <Link to={'//www.linkedin.com/company/sthlm-tech-partner'} target={'_blank'}>
                      <img src={LinkedIn}
                        width="12px"
                        height="12px"
                        loading="lazy"
                        title="linkedinIcon"

                        alt="linkedinIcon" />
                    </Link>
                    <Link to={'//www.instagram.com/sthlmtechpartner.official/?hl=en'} target={'_blank'}>
                      <img
                        src={Instagram}
                        width="11px"
                        height="11px"
                        loading="lazy"
                        alt="instagramIcon"
                        title="instagramIcon"

                      />
                    </Link>
                  </Box>
                </Box>
                <Box
                  sx={{
                    width: {
                      xl: "80%",
                      lg: "80%",
                      md: "80%",
                      sm: "100%",
                      xs: "100%",
                    },
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: "bold",
                      fontSize: "1rem",
                      paddingLeft: {
                        xl: "2rem",
                        lg: "2rem",
                        md: "2rem",
                        sm: "0",
                        xs: "0",
                      },
                    }}
                  >
                    {props.bodyContent.introduction}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  paddingLeft: {
                    xl: "20%",
                    lg: "20%",
                    md: "20%",
                    sm: "0",
                    xs: "0",
                  },
                  width: "95%",
                }}
              >
                <Typography
                  variant="h5"
                  sx={{ fontFamily: "Inter", marginTop: "2rem" }}
                >
                  {props.bodyContent.heading1}
                </Typography>
                <Typography
                  variant="body1"
                  style={{
                    marginTop: "1.5rem",
                    marginBottom: "1rem",
                  }}
                >
                  {props.bodyContent.content1Paragraph1}
                </Typography>
                <Typography
                  variant="h5"
                  sx={{ fontFamily: "Inter", marginTop: "2rem" }}
                >
                  {props.bodyContent.heading2}
                </Typography>

                <Typography
                  variant="body1"
                  style={{
                    marginTop: "1.5rem",
                    marginBottom: "1rem",
                  }}
                >
                  {props.bodyContent.content2Paragraph1}
                </Typography>

                <Typography
                  variant="h6"
                  style={{
                    marginTop: "1.5rem",
                    marginBottom: "1rem",
                  }}
                >
                  {props.bodyContent.heading2Point1}
                </Typography>
                <Typography
                  variant="body1"
                  style={{
                    marginTop: "1.5rem",
                    marginBottom: "1rem",
                  }}
                >
                  {props.bodyContent.heading2Point1Content}
                </Typography>

                <Typography
                  variant="h6"
                  sx={{ fontFamily: "Inter", marginTop: "2rem" }}
                >
                  {props.bodyContent.heading2Point2}
                </Typography>
                <Typography
                  variant="body1"
                  style={{
                    marginTop: "1.5rem",
                    marginBottom: "1rem",
                  }}
                >
                  {props.bodyContent.heading2Point2Content}
                </Typography>
                <Typography
                  variant="h6"
                  sx={{ fontFamily: "Inter", marginTop: "2rem" }}
                >
                  {props.bodyContent.heading3Point3}
                </Typography>
                <Typography
                  variant="body1"
                  style={{
                    marginTop: "1.5rem",
                    marginBottom: "1rem",
                  }}
                >
                  {props.bodyContent.heading3Point3Content}
                </Typography>
                <Typography
                  variant="h6"
                  sx={{ fontFamily: "Inter", marginTop: "2rem" }}
                >
                  {props.bodyContent.heading4Point4}
                </Typography>
                <Typography
                  variant="body1"
                  style={{
                    marginTop: "1.5rem",
                    marginBottom: "1rem",
                  }}
                >
                  {props.bodyContent.heading4Point4Content}
                </Typography>
                <Typography
                  variant="h6"
                  sx={{ fontFamily: "Inter", marginTop: "2rem" }}
                >
                  {props.bodyContent.heading5Point5}
                </Typography>
                <Typography
                  variant="body1"
                  style={{
                    marginTop: "1.5rem",
                    marginBottom: "1rem",
                  }}
                >
                  {props.bodyContent.heading5Point5Content}
                </Typography>
                <Typography
                  variant="h5"
                  style={{
                    marginTop: "1.5rem",
                    marginBottom: "1rem",
                  }}
                >
                  {props.bodyContent.heading3}
                </Typography>
                <Typography
                  variant="body1"
                  style={{
                    marginTop: "1.5rem",
                    marginBottom: "1rem",
                  }}
                >
                  {props.bodyContent.content3Paragraph1}
                </Typography>
                <Typography
                  variant="h6"
                  sx={{ fontFamily: "Inter", marginTop: "2rem" }}
                >
                  {props.bodyContent.heading3Point1}
                </Typography>
                <Typography
                  variant="body1"
                  style={{
                    marginTop: "1.5rem",
                    marginBottom: "1rem",
                  }}
                >
                  {props.bodyContent.heading3Point1Content}
                </Typography>
                <Typography
                  variant="h6"
                  sx={{ fontFamily: "Inter", marginTop: "2rem" }}
                >
                  {props.bodyContent.heading3Point2}
                </Typography>
                <Typography
                  variant="body1"
                  style={{
                    marginTop: "1.5rem",
                    marginBottom: "1rem",
                  }}
                >
                  {props.bodyContent.heading3Point2Content}
                </Typography>
                <Typography
                  variant="h6"
                  sx={{ fontFamily: "Inter", marginTop: "2rem" }}
                >
                  {props.bodyContent.heading3Point3}
                </Typography>
                <Typography
                  variant="body1"
                  style={{
                    marginTop: "1.5rem",
                    marginBottom: "1rem",
                  }}
                >
                  {props.bodyContent.heading3Point3Content}
                </Typography>
                <Typography
                  variant="h6"
                  sx={{ fontFamily: "Inter", marginTop: "2rem" }}
                >
                  {props.bodyContent.heading3Point4}
                </Typography>
                <Typography
                  variant="body1"
                  style={{
                    marginTop: "1.5rem",
                    marginBottom: "1rem",
                  }}
                >
                  {props.bodyContent.heading3Point4Content}
                </Typography>
                <Typography
                  variant="h6"
                  sx={{ fontFamily: "Inter", marginTop: "2rem" }}
                >
                  {props.bodyContent.heading3Point5}
                </Typography>
                <Typography
                  variant="body1"
                  style={{
                    marginTop: "1.5rem",
                    marginBottom: "1rem",
                  }}
                >
                  {props.bodyContent.heading3Point5Content}
                </Typography>

                <Typography
                  variant="h3"
                  style={{
                    fontWeight: "500",
                    marginTop: "1.5rem",
                    marginBottom: "1rem",
                    fontSize: '2rem'
                  }}
                >
                  {props.bodyContent.faqs}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontWeight: "500", marginTop: "1rem" }}
                >
                  {props.bodyContent.question1}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontWeight: "500", marginTop: "1rem" }}
                >
                  {props.bodyContent.answer1}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontWeight: "500", marginTop: "1rem" }}
                >
                  {props.bodyContent.question2}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontWeight: "500", marginTop: "1rem" }}
                >
                  {props.bodyContent.answer2}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontWeight: "500", marginTop: "1rem" }}
                >
                  {props.bodyContent.question3}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontWeight: "500", marginTop: "1rem" }}
                >
                  {props.bodyContent.answer3}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontWeight: "500", marginTop: "1rem" }}
                >
                  {props.bodyContent.question4}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontWeight: "500", marginTop: "1rem" }}
                >
                  {props.bodyContent.answer4}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontWeight: "500", marginTop: "1rem" }}
                >
                  {props.bodyContent.question5}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontWeight: "500", marginTop: "1rem" }}
                >
                  {props.bodyContent.answer5}
                </Typography>
                <Typography
                  variant="h3"
                  style={{
                    fontWeight: "500",
                    marginTop: "1.5rem",
                    marginBottom: "1rem",
                    fontSize: '2rem'
                  }}
                >
                  {props.bodyContent.conclusion}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontWeight: "500", marginTop: "1rem" }}
                >
                  {props.bodyContent.conclusionContent}
                </Typography>
              </Box>
              <Box
                style={{ paddingTop: "1rem" }}
                // className={`${classes.subBox}`}
                className={classes.socialHide}
              >
                <Box>
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: "1rem",
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      fontWeight: "bold",
                      paddingBottom: "1rem",
                    }}
                  >
                    Share this article
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    // width: "60%",
                  }}
                >
                  <Link to={'//www.facebook.com/sthlmtechpartner/'} target={'_blank'}>
                    <img
                      src={Facebook}
                      style={{ marginRight: ".5rem" }}
                      width="9px"
                      height="15.5px"
                      alt="facebookIcon"
                      title="facebookIcon"

                      loading="lazy"
                    />
                  </Link>
                  <Link to={'//mobile.twitter.com/sthlmtechprtnr'} target={'_blank'}>
                    <img
                      src={Twitter}
                      width="11.5px"
                      height="10.5px"
                      loading="lazy"
                      alt="twitterIcon"
                      title="twitterIcon"

                      style={{ marginRight: ".5rem" }}
                    />
                  </Link>
                  <Link to={'//www.linkedin.com/company/sthlm-tech-partner'} target={'_blank'}>
                    <img
                      src={LinkedIn}
                      width="12px"
                      height="12px"
                      loading="lazy"
                      alt="linkedinIcon"
                      title="linkedinIcon"

                      style={{ marginRight: ".5rem" }}
                    />
                  </Link>
                  <Link to={'//www.instagram.com/sthlmtechpartner.official/?hl=en'} target={'_blank'}>
                    <img
                      src={Instagram}
                      width="11px"
                      height="11px"
                      loading="lazy"
                      alt="instagramIcon"
                      title="instagramIcon"
                      style={{ marginRight: ".5rem" }}
                    />
                  </Link>
                </Box>
              </Box>
              <Box
                className={classes.insideBoxes}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  className={classes.insideBoxes}
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  {blogCardContent.map((item, index) => {
                    return (

                      <Box
                        sx={{
                          marginTop: "2rem",
                          marginBottom: {
                            xl: "3rem",
                            lg: "3rem",
                            md: "3rem",
                            sm: "0",
                            xs: "0",
                          },
                        }}
                        key={index}
                      >
                        <BlogCard
                          sx={{
                            fontSize: "1rem",
                            color: "#fff",
                            fontWeight: 540,
                            lineHeight: "1.5rem",
                          }}
                          title=""
                          width="17rem"
                          height="22rem"
                          description={item.description}
                          backgroundImage={item.backgroundImage}
                          arrowButton={<ArrowButton />}
                          onClick={() =>
                            navigate(`/blogs/${props.url}`, {
                              state: item.content,
                            })
                          }
                        />
                      </Box>

                    );
                  })}
                </Box>
              </Box>
            </Container>
          </Container >
          <Footer />
        </>
      );
    case "bus-app-benefits":
      return (
        <>
          <Helmet>
            <title>{props.mainHeading}</title>
            <meta name="description" content={props.metaDescription} />
            <link rel="canonical" href={`/blogs/${props.url}`} />
          </Helmet>
          <Container maxWidth="xl" sx={{ marginTop: "8rem", bgcolor: "#fff" }}>
            <Container maxWidth="md">
              <Box>
                <Typography variant="h6" sx={{ fontFamily: "Inter" }}>
                  {props.subHeading}
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant="h1"
                  sx={{
                    width: {
                      xl: "70%",
                      lg: "70%",
                      md: "70%",
                      sm: "100%",
                      xs: "100%",
                    },
                    fontFamily: "Noto Serif",
                    fontSize: '2rem'
                  }}
                >
                  {props.mainHeading}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  marginTop: "2rem",
                  width: "100%",
                }}
              >
                <img src={Vector}
                  width="20px"
                  height="20px"
                  title="calendar"
                  loading="lazy"
                  alt="Vector" />
                <Typography sx={{ marginLeft: "1rem", marginRight: "2rem" }}>
                  {props.date}
                </Typography>
                <img src={Rawan}
                  width="20px"
                  height="20px"
                  title="profile"
                  loading="lazy" alt="Rawan" />
                <Typography sx={{ marginLeft: "1rem" }}>
                  {props.author}
                </Typography>
              </Box>
              <Box
                sx={{
                  paddingTop: "3rem",
                  display: "flex",
                  justifyContent: {
                    xl: "flex-end",
                    lg: "flex-end",
                    md: "flex-end",
                    sm: "center",
                    xs: "center",
                  },
                }}
              >
                <img
                  src={props.contentImage}
                  alt={props.bodyContent.imageAlt}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    borderRadius: ".5rem",
                  }}
                  width="100%"
                  height="100%"
                  title={props.bodyContent.imageTitle}
                  loading="lazy"
                  className={classes.imgMain}
                />
              </Box>
              <Box
                className={classes.ImageInside}
                sx={{
                  paddingTop: "3rem",
                  display: "flex",
                }}
              >
                <Box className={`${classes.subBox} ${classes.socialHandle}`}>
                  <Box>
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: "1rem",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontWeight: "bold",
                        paddingBottom: "1rem",
                      }}
                    >
                      Share this article
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Link to={'//www.facebook.com/sthlmtechpartner/'} target={'_blank'}>
                      <img
                        src={Facebook}
                        width="9px"
                        height="15.5px"
                        alt="facebookIcon"
                        title="facebookIcon"
                        loading="lazy"
                      />
                    </Link>
                    <Link to={'//mobile.twitter.com/sthlmtechprtnr'} target={'_blank'}>
                      <img src={Twitter} width="11.5px"
                        height="10.5px"
                        loading="lazy"
                        title="twitterIcon"

                        alt="twitterIcon" />
                    </Link>
                    <Link to={'//www.linkedin.com/company/sthlm-tech-partner'} target={'_blank'}>
                      <img src={LinkedIn}
                        width="12px"
                        height="12px"
                        loading="lazy"
                        title="linkedinIcon"

                        alt="linkedinIcon" />
                    </Link>
                    <Link to={'//www.instagram.com/sthlmtechpartner.official/?hl=en'} target={'_blank'}>
                      <img
                        src={Instagram}
                        width="11px"
                        height="11px"
                        loading="lazy"
                        alt="instagramIcon"
                        title="instagramIcon"

                      />
                    </Link>
                  </Box>
                </Box>
                <Box
                  sx={{
                    width: {
                      xl: "80%",
                      lg: "80%",
                      md: "80%",
                      sm: "100%",
                      xs: "100%",
                    },
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: "bold",
                      fontSize: "1rem",
                      paddingLeft: {
                        xl: "2rem",
                        lg: "2rem",
                        md: "2rem",
                        sm: "0",
                        xs: "0",
                      },
                    }}
                  >
                    {props.bodyContent.introduction}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  paddingLeft: {
                    xl: "20%",
                    lg: "20%",
                    md: "20%",
                    sm: "0",
                    xs: "0",
                  },
                  width: "95%",
                }}
              >

                <Typography
                  variant="body1"
                  style={{
                    marginTop: "1.5rem",
                    marginBottom: "1rem",
                  }}
                >
                  {props.bodyContent.content1Paragraph1}
                </Typography>
                <Typography
                  variant="h6"
                  sx={{ fontFamily: "Inter", marginTop: "2rem" }}
                >
                  {props.bodyContent.heading2Point1}
                </Typography>

                <Typography
                  variant="body1"
                  style={{
                    marginTop: "1.5rem",
                    marginBottom: "1rem",
                  }}
                >
                  {props.bodyContent.heading2Point1Content1}
                </Typography>
                <Typography
                  variant="body1"
                  style={{
                    marginTop: "1.5rem",
                    marginBottom: "1rem",
                  }}
                >
                  {props.bodyContent.heading2Point1Content2}
                </Typography>
                <Typography
                  variant="h6"
                  style={{
                    marginTop: "1.5rem",
                    marginBottom: "1rem",
                  }}
                >
                  {props.bodyContent.heading2Point2}
                </Typography>
                <Typography
                  variant="body1"
                  style={{
                    marginTop: "1.5rem",
                    marginBottom: "1rem",
                  }}
                >
                  {props.bodyContent.heading2Point2Content1}
                </Typography>
                <Typography
                  variant="body1"
                  style={{
                    marginTop: "1.5rem",
                    marginBottom: "1rem",
                  }}
                >
                  {props.bodyContent.heading2Point2Content2}
                </Typography>
                <Typography
                  variant="body1"
                  style={{
                    marginTop: "1.5rem",
                    marginBottom: "1rem",
                  }}
                >
                  {props.bodyContent.heading2Point2Content3}
                </Typography>
                <Typography
                  variant="h6"
                  sx={{ fontFamily: "Inter", marginTop: "2rem" }}
                >
                  {props.bodyContent.heading2Point3}
                </Typography>
                <Typography
                  variant="body1"
                  style={{
                    marginTop: "1.5rem",
                    marginBottom: "1rem",
                  }}
                >
                  {props.bodyContent.heading2Point3Content1}
                </Typography>
                <Typography
                  variant="body1"
                  style={{
                    marginTop: "1.5rem",
                    marginBottom: "1rem",
                  }}
                >
                  {props.bodyContent.heading2Point3Content2}
                </Typography>
                <Typography
                  variant="h6"
                  sx={{ fontFamily: "Inter", marginTop: "2rem" }}
                >
                  {props.bodyContent.heading2Point4}
                </Typography>
                <Typography
                  variant="body1"
                  style={{
                    marginTop: "1.5rem",
                    marginBottom: "1rem",
                  }}
                >
                  {props.bodyContent.heading2Point4Content1}
                </Typography>
                <Typography
                  variant="body1"
                  style={{
                    marginTop: "1.5rem",
                    marginBottom: "1rem",
                  }}
                >
                  {props.bodyContent.heading2Point4Content2}
                </Typography>
                <Typography
                  variant="h6"
                  sx={{ fontFamily: "Inter", marginTop: "2rem" }}
                >
                  {props.bodyContent.heading2Point5}
                </Typography>
                <Typography
                  variant="body1"
                  style={{
                    marginTop: "1.5rem",
                    marginBottom: "1rem",
                  }}
                >
                  {props.bodyContent.heading2Point5Content1}
                </Typography>
                <Typography
                  variant="body1"
                  style={{
                    marginTop: "1.5rem",
                    marginBottom: "1rem",
                  }}
                >
                  {props.bodyContent.heading2Point5Content2}
                </Typography>

                <Typography
                  variant="h3"
                  style={{
                    fontWeight: "500",
                    marginTop: "1.5rem",
                    marginBottom: "1rem",
                    fontSize: '2rem'
                  }}
                >
                  {props.bodyContent.faqs}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontWeight: "500", marginTop: "1rem" }}
                >
                  {props.bodyContent.question1}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontWeight: "500", marginTop: "1rem" }}
                >
                  {props.bodyContent.answer1}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontWeight: "500", marginTop: "1rem" }}
                >
                  {props.bodyContent.question2}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontWeight: "500", marginTop: "1rem" }}
                >
                  {props.bodyContent.answer2}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontWeight: "500", marginTop: "1rem" }}
                >
                  {props.bodyContent.question3}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontWeight: "500", marginTop: "1rem" }}
                >
                  {props.bodyContent.answer3}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontWeight: "500", marginTop: "1rem" }}
                >
                  {props.bodyContent.question4}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontWeight: "500", marginTop: "1rem" }}
                >
                  {props.bodyContent.answer4}
                </Typography>
                <Typography
                  variant="h3"
                  style={{
                    fontWeight: "500",
                    marginTop: "1.5rem",
                    marginBottom: "1rem",
                    fontSize: '2rem'
                  }}
                >
                  {props.bodyContent.conclusion}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontWeight: "500", marginTop: "1rem" }}
                >
                  {props.bodyContent.conclusionContent}
                </Typography>
              </Box>
              <Box
                style={{ paddingTop: "1rem" }}
                // className={`${classes.subBox}`}
                className={classes.socialHide}
              >
                <Box>
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: "1rem",
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      fontWeight: "bold",
                      paddingBottom: "1rem",
                    }}
                  >
                    Share this article
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    // width: "60%",
                  }}
                >
                  <Link to={'//www.facebook.com/sthlmtechpartner/'} target={'_blank'}>
                    <img
                      src={Facebook}
                      style={{ marginRight: ".5rem" }}
                      width="9px"
                      height="15.5px"
                      alt="facebookIcon"
                      title="facebookIcon"

                      loading="lazy"
                    />
                  </Link>
                  <Link to={'//mobile.twitter.com/sthlmtechprtnr'} target={'_blank'}>
                    <img
                      src={Twitter}
                      width="11.5px"
                      height="10.5px"
                      loading="lazy"
                      alt="twitterIcon"
                      title="twitterIcon"

                      style={{ marginRight: ".5rem" }}
                    />
                  </Link>
                  <Link to={'//www.linkedin.com/company/sthlm-tech-partner'} target={'_blank'}>
                    <img
                      src={LinkedIn}
                      width="12px"
                      height="12px"
                      loading="lazy"
                      alt="linkedinIcon"
                      title="linkedinIcon"

                      style={{ marginRight: ".5rem" }}
                    />
                  </Link>
                  <Link to={'//www.instagram.com/sthlmtechpartner.official/?hl=en'} target={'_blank'}>
                    <img
                      src={Instagram}
                      width="11px"
                      height="11px"
                      loading="lazy"
                      alt="instagramIcon"
                      title="instagramIcon"
                      style={{ marginRight: ".5rem" }}
                    />
                  </Link>
                </Box>
              </Box>
              <Box
                className={classes.insideBoxes}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  className={classes.insideBoxes}
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  {blogCardContent.map((item, index) => {
                    return (

                      <Box
                        sx={{
                          marginTop: "2rem",
                          marginBottom: {
                            xl: "3rem",
                            lg: "3rem",
                            md: "3rem",
                            sm: "0",
                            xs: "0",
                          },
                        }}
                        key={index}
                      >
                        <BlogCard
                          sx={{
                            fontSize: "1rem",
                            color: "#fff",
                            fontWeight: 540,
                            lineHeight: "1.5rem",
                          }}
                          title=""
                          width="17rem"
                          height="22rem"
                          description={item.description}
                          backgroundImage={item.backgroundImage}
                          arrowButton={<ArrowButton />}
                          onClick={() =>
                            navigate(`/blogs/${props.url}`, {
                              state: item.content,
                            })
                          }
                        />
                      </Box>

                    );
                  })}
                </Box>
              </Box>
            </Container>
          </Container >
          <Footer />
        </>
      );
    case "normal":
      return (
        <>
          <Helmet>
            <title>{props.mainHeading}</title>
            <meta name="description" content={props.metaDescription} />
            <link rel="canonical" href={`/blogs/${props.url}`} />
          </Helmet>
          <Container maxWidth="xl" sx={{ marginTop: "8rem", bgcolor: "#fff" }}>
            <Container maxWidth="md">
              <Box>
                <Typography variant="h6" sx={{ fontFamily: "Inter" }}>
                  {props.subHeading}
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant="h1"
                  sx={{
                    width: {
                      xl: "70%",
                      lg: "70%",
                      md: "70%",
                      sm: "100%",
                      xs: "100%",
                    },
                    fontFamily: "Noto Serif",
                    fontSize: '2rem'
                  }}
                >
                  {props.mainHeading}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  marginTop: "2rem",
                  width: "100%",
                }}
              >
                <img src={Vector}
                  width="20px"
                  height="20px"
                  title="calendar"
                  loading="lazy"
                  alt="Vector" />
                <Typography sx={{ marginLeft: "1rem", marginRight: "2rem" }}>
                  {props.date}
                </Typography>
                <img src={Rawan}
                  width="20px"
                  height="20px"
                  title="profile"
                  loading="lazy" alt="Rawan" />
                <Typography sx={{ marginLeft: "1rem" }}>
                  {props.author}
                </Typography>
              </Box>
              <Box
                sx={{
                  paddingTop: "3rem",
                  display: "flex",
                  justifyContent: {
                    xl: "flex-end",
                    lg: "flex-end",
                    md: "flex-end",
                    sm: "center",
                    xs: "center",
                  },
                }}
              >
                <img
                  src={props.contentImage}
                  alt={props.bodyContent.imageAlt}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    borderRadius: ".5rem",
                  }}
                  width="100%"
                  height="100%"
                  title={props.bodyContent.imageTitle}
                  loading="lazy"
                  className={classes.imgMain}
                />
              </Box>
              <Box
                className={classes.ImageInside}
                sx={{
                  paddingTop: "3rem",
                  display: "flex",
                }}
              >
                <Box className={`${classes.subBox} ${classes.socialHandle}`}>
                  <Box>
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: "1rem",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontWeight: "bold",
                        paddingBottom: "1rem",
                      }}
                    >
                      Share this article
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Link to={'//www.facebook.com/sthlmtechpartner/'} target={'_blank'}>
                      <img
                        src={Facebook}
                        width="9px"
                        height="15.5px"
                        alt="facebookIcon"
                        title="facebookIcon"
                        loading="lazy"
                      />
                    </Link>
                    <Link to={'//mobile.twitter.com/sthlmtechprtnr'} target={'_blank'}>
                      <img src={Twitter} width="11.5px"
                        height="10.5px"
                        loading="lazy"
                        title="twitterIcon"

                        alt="twitterIcon" />
                    </Link>
                    <Link to={'//www.linkedin.com/company/sthlm-tech-partner'} target={'_blank'}>
                      <img src={LinkedIn}
                        width="12px"
                        height="12px"
                        loading="lazy"
                        title="linkedinIcon"

                        alt="linkedinIcon" />
                    </Link>
                    <Link to={'//www.instagram.com/sthlmtechpartner.official/?hl=en'} target={'_blank'}>
                      <img
                        src={Instagram}
                        width="11px"
                        height="11px"
                        loading="lazy"
                        alt="instagramIcon"
                        title="instagramIcon"

                      />
                    </Link>
                  </Box>
                </Box>
                <Box
                  sx={{
                    width: {
                      xl: "80%",
                      lg: "80%",
                      md: "80%",
                      sm: "100%",
                      xs: "100%",
                    },
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: "bold",
                      fontSize: "1rem",
                      paddingLeft: {
                        xl: "2rem",
                        lg: "2rem",
                        md: "2rem",
                        sm: "0",
                        xs: "0",
                      },
                    }}
                  >
                    {props.bodyContent.boldParagraph}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  paddingLeft: {
                    xl: "20%",
                    lg: "20%",
                    md: "20%",
                    sm: "0",
                    xs: "0",
                  },
                  width: "95%",
                }}
              >

                <Typography
                  variant="body1"
                  style={{
                    marginTop: "1.5rem",
                    marginBottom: "1rem",
                  }}
                >
                  {props.bodyContent.regularParagraph}
                </Typography>
                <Typography
                  variant="body1"
                  style={{
                    marginTop: "1.5rem",
                    marginBottom: "1rem",
                  }}
                >
                  {props.bodyContent.regularParagraphtype}
                </Typography>

                <Typography
                  variant="h6"
                  style={{
                    marginTop: "1.5rem",
                    marginBottom: "1rem",
                  }}
                >
                  {props.bodyContent.bodyHeading}
                </Typography>
                <Typography
                  variant="body1"
                  style={{
                    marginTop: "1.5rem",
                    marginBottom: "1rem",
                  }}
                >
                  {props.bodyContent.bodyParagraph}
                </Typography>
              </Box>
              <Box
                style={{ paddingTop: "1rem" }}
                // className={`${classes.subBox}`}
                className={classes.socialHide}
              >
                <Box>
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: "1rem",
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      fontWeight: "bold",
                      paddingBottom: "1rem",
                    }}
                  >
                    Share this article
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    // width: "60%",
                  }}
                >
                  <Link to={'//www.facebook.com/sthlmtechpartner/'} target={'_blank'}>
                    <img
                      src={Facebook}
                      style={{ marginRight: ".5rem" }}
                      width="9px"
                      height="15.5px"
                      alt="facebookIcon"
                      title="facebookIcon"

                      loading="lazy"
                    />
                  </Link>
                  <Link to={'//mobile.twitter.com/sthlmtechprtnr'} target={'_blank'}>
                    <img
                      src={Twitter}
                      width="11.5px"
                      height="10.5px"
                      loading="lazy"
                      alt="twitterIcon"
                      title="twitterIcon"

                      style={{ marginRight: ".5rem" }}
                    />
                  </Link>
                  <Link to={'//www.linkedin.com/company/sthlm-tech-partner'} target={'_blank'}>
                    <img
                      src={LinkedIn}
                      width="12px"
                      height="12px"
                      loading="lazy"
                      alt="linkedinIcon"
                      title="linkedinIcon"

                      style={{ marginRight: ".5rem" }}
                    />
                  </Link>
                  <Link to={'//www.instagram.com/sthlmtechpartner.official/?hl=en'} target={'_blank'}>
                    <img
                      src={Instagram}
                      width="11px"
                      height="11px"
                      loading="lazy"
                      alt="instagramIcon"
                      title="instagramIcon"
                      style={{ marginRight: ".5rem" }}
                    />
                  </Link>
                </Box>
              </Box>
              <Box
                className={classes.insideBoxes}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  className={classes.insideBoxes}
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  {blogCardContent.map((item, index) => {
                    return (

                      <Box
                        sx={{
                          marginTop: "2rem",
                          marginBottom: {
                            xl: "3rem",
                            lg: "3rem",
                            md: "3rem",
                            sm: "0",
                            xs: "0",
                          },
                        }}
                        key={index}
                      >
                        <BlogCard
                          sx={{
                            fontSize: "1rem",
                            color: "#fff",
                            fontWeight: 540,
                            lineHeight: "1.5rem",
                          }}
                          title=""
                          width="17rem"
                          height="22rem"
                          description={item.description}
                          backgroundImage={item.backgroundImage}
                          arrowButton={<ArrowButton />}
                          onClick={() =>
                            navigate(`/blogs/${props.url}`, {
                              state: item.content,
                            })
                          }
                        />
                      </Box>

                    );
                  })}
                </Box>
              </Box>
            </Container>
          </Container >
          <Footer />
        </>
      );
    default:
      return <Container maxWidth="xl" sx={{ marginTop: "8rem", bgcolor: "#fff" }}>
        <Container maxWidth="md">
          <Typography variant="h4">
            Invalid blog post
          </Typography>
        </Container>
      </Container >;
  }
};

export default BlogSingle;
