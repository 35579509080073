import React from "react";
import { Container, Box, Typography } from "@mui/material";
import Storytel from "../../../assets/images/StoryteliOSApplication.webp";
import Cmore from "../../../assets/images/CmoreDevelopersiOS.webp";
import McDonalds from "../../../assets/images/McDonaldsiOSDevelopment.webp";
import Jobylon from "../../../assets/images/Jobylon.webp";
import Fryan from "../../../assets/images/Fryan.webp";
import Taxi from "../../../assets/images/TaxiSthlmiOSProgrammer.webp";
import HNMGrey from "../../../assets/images/HNMDevelopiphoneApp.webp";
import folksamGrey from "../../../assets/images/FolksamiPhoneMobile.webp";
import dometicGrey from "../../../assets/images/DometicReactNativeApp.webp";

const OurPartners = () => {
  return (
    <div>
      <Container disableGutters maxWidth="xl">
        <Container
          maxWidth="md"
          sx={{
            paddingTop: "4rem",
            paddingBottom: "4rem",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: "2.2rem",
              fontFamily: "Noto Serif",
              marginBottom: "2rem",
            }}
          >
            Our Partners
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <img
              src={HNMGrey}
              style={{ padding: "0.5rem" }}
              alt="HNM Ios App"
              width="80px"
              height="58.4px"
              loading="lazy"
              title="HNM Ios App"
            />
            <img
              src={folksamGrey}
              style={{ padding: "0.5rem" }}
              alt="Folksam iOS App"
              width="80px"
              height="29.45px"
              loading="lazy"
              title="Folksam iOS App"
            />
            <img
              src={dometicGrey}
              style={{ padding: "0.5rem" }}
              alt="Dometic React Native"
              width="80px"
              height="24.81px"
              loading="lazy"
              title="Dometic React Native"
            />
            <img
              src={Taxi}
              style={{ padding: "0.5rem" }}
              alt="Taxi Stockholm Apple development"
              width="80px"
              height="56.41px"
              loading="lazy"
              title="Taxi Stockholm Apple development"
            />
            <img
              src={Cmore}
              style={{ padding: "0.5rem" }}
              alt="Cmore Web App"
              width="126px"
              height="36px"
              loading="lazy"
              title="Cmore Web App"
            />
            <img
              src={Storytel}
              style={{ padding: "0.5rem" }}
              alt="Storytel iOS App"
              width="96px"
              height="66.14px"
              loading="lazy"
              title="Storytel iOS App"
            />
            <img
              src={McDonalds}
              style={{ padding: "0.5rem" }}
              alt="McDonalds App Mobile"
              width="64px"
              height="62.91px"
              loading="lazy"
              title="McDonalds App Mobile"
            />
            <img
              src={Fryan}
              style={{ padding: "0.5rem" }}
              alt="Fryan Web App"
              width="64px"
              height="64px"
              loading="lazy"
              title="Fryan Web App"
            />
            <img
              src={Jobylon}
              style={{ padding: "0.5rem" }}
              alt="Jobylon Web App"
              width="112.64px"
              height="48px"
              loading="lazy"
              title="Jobylon Web App"
            />
          </Box>
        </Container>
      </Container>
    </div>
  );
};

export default OurPartners;
