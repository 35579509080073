import React, { Fragment } from "react";
import {
  CssBaseline,
  Container,
  Box,
  Typography,
  TextField,
} from "@mui/material";
import PrimaryButton from "../../atom/PrimaryButton/PrimaryButton";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => ({
  contactMobileView: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
    },
  },
  fieldsWidth: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.up("md")]: {
      width: "50%",
    },
  },
  contentWidth: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.up("md")]: {
      width: "40%",
    },
  },
}));

interface Content {
  title: string;
  description: any;
}

const ContactForm = (props: Content) => {
  const classes = useStyles();

  return (
    <Fragment>
      <CssBaseline />
      <Container disableGutters>
        <Container
          maxWidth="md"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingTop: "4rem",
            paddingBottom: "4rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
            className={classes.contactMobileView}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                marginTop: "2rem",
              }}
              className={classes.contentWidth}
            >
              <Typography
                variant="h2"
                sx={{
                  fontSize: {
                    lg: "1rem",
                    md: "1rem",
                    sm: "1.5rem",
                    xs: "1.5rem",
                  },
                  fontWeight: 550,
                  color: "#000",
                  marginBottom: "1.5rem",
                }}
              >
                {props.title}
              </Typography>
              <Typography
                variant="h3"
                sx={{
                  fontFamily: "Noto Serif",
                  marginBottom: "1rem",
                  fontSize: {
                    lg: "2.5rem",
                    md: "2.5rem",
                    sm: "1.25rem",
                    xs: "1.25rem",
                  },
                }}
              >
                {props.description}
              </Typography>
            </Box>
            <Box className={classes.fieldsWidth}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "center",
                }}
              >
                <TextField
                  sx={{ width: "100%", marginBottom: "1rem" }}
                  id="standard-basic"
                  label="Your name"
                  variant="standard"
                />
                <TextField
                  sx={{ width: "100%", marginBottom: "1rem" }}
                  id="standard-basic"
                  label="Your email"
                  variant="standard"
                />
                <TextField
                  sx={{ width: "100%", marginBottom: "1rem" }}
                  id="standard-basic"
                  label="Your phone number"
                  variant="standard"
                />
                <TextField
                  sx={{ width: "100%", marginBottom: "1rem" }}
                  id="standard-basic"
                  label="Message"
                  variant="standard"
                />
              </Box>
              <Box
                sx={{
                  marginTop: "1rem",
                  width: {
                    xs: "100%",
                    sm: "100%",
                    md: "30%",
                    lg: "30%",
                    xl: "30%",
                  },
                }}
              >
                <PrimaryButton title="Send message" />
              </Box>
            </Box>
          </Box>
        </Container>
      </Container>
    </Fragment>
  );
};

export default ContactForm;
