import React from "react";
import { Box, Typography } from "@mui/material";

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => ({
  hover: {
    "&:hover": {
      backgroundColor: "#6D30BA2",
      cursor: "pointer",
    },
  },
}));

export interface BlogContent {
  title: string;
  sx: any;
  description: string;
  backgroundImage: any;
  arrowButton: any;
  width: any;
  height: any;
  onClick: () => void;
}

const BlogCard = (props: BlogContent) => {
  const classes = useStyles();

  return (
    <div>
      <Box
        sx={{
          backgroundImage: `url(${props.backgroundImage})`,
          width: {
            xs: "100%",
            sm: "100%",
            md: props.width,
            lg: props.width,
            xl: props.width,
          },
          height: {
            xs: "20rem",
            sm: "20rem",
            md: props.height,
            lg: props.height,
            xl: props.height,
          },
          backgroundSize: "cover",
          backgroundPosition: "center",
          zIndex: -1,
          padding: "2rem",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "flex-end",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <Typography
            variant="h2"
            sx={{
              fontSize: "1.25rem",
              color: "#fff",
              fontWeight: 600,
              lineHeight: "1.5rem",
            }}
          >
            {props.title}
          </Typography>
          <Typography variant="h3" sx={props.sx}>
            {props.description}
          </Typography>
          <Box
            className={classes.hover}
            sx={{
              backgroundColor: "#fff",
              borderRadius: "100px",
              marginTop: ".75rem",
            }}
            onClick={props.onClick}
          >
            {props.arrowButton}
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default BlogCard;
