import React from "react";
import { Helmet } from "react-helmet-async";
import AboutUs from "../../components/organism/AboutUs/AboutUs";

const AboutPage = () => {
  return (
    <div>
      <Helmet>
        <title>About Us</title>
        <meta name="description" content="Sthlmtechpartner is a passionate advocate for Gender Equality issues, as our core business revolves around app development services." />
        <link rel="canonical" href="/about" />
      </Helmet>
      <AboutUs maxWidth="false" />
    </div>
  );
};

export default AboutPage;
