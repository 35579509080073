import React, { Fragment } from "react";
import { CssBaseline, Container, Box, Typography } from "@mui/material";
import ArrowButton from "../../atom/ArrowButton/ArrowButton";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => ({
  serviceMobileView: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
    },
  },
  hideMobileImage: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    [theme.breakpoints.up("1500")]: {
      display: "none",
    },
  },
  hideBlock: {
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  flexStart: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "flex-end",
      flexDirection: "column",
    },
  },
}));

interface Style {
  maxWidth: any;
}

const Service = (props: Style) => {
  const classes = useStyles();
  const navigate = useNavigate();
  return (
    <Fragment>
      <CssBaseline />
      <Container
        disableGutters
        maxWidth={props.maxWidth}
        sx={{ bgcolor: "#f4f4f4", paddingBottom: "3rem" }}
      >
        <Container
          maxWidth="md"
          sx={{
            display: "flex",
            paddingTop: "4rem",
            paddingBottom: "2rem",
          }}
          className={classes.serviceMobileView}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              width: "40%",
            }}
          >
            <Typography
              variant="h2"
              sx={{
                fontSize: {
                  lg: "1rem",
                  md: "1rem",
                  sm: "1.5rem",
                  xs: "1.5rem",
                },
                fontWeight: 550,
                color: "#000",
                marginBottom: "1.5rem",
              }}
            >
              Services
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
            className={classes.flexStart}
          >
            <Box
              sx={{
                width: {
                  xl: "85%",
                  lg: "85%",
                  md: "85%",
                  sm: "100%",
                  xs: "100%",
                },
              }}
            >
              <Typography
                variant="h3"
                sx={{
                  fontFamily: "Noto Serif",
                  marginBottom: "1rem",
                  fontSize: {
                    lg: "2rem",
                    md: "2rem",
                    sm: "1.25rem",
                    xs: "1.25rem",
                  },
                }}
              >
                We know it is quite challenging to find a good team to do great
                things.{" "}
                <span style={{ color: "#6D30BA", fontWeight: 600 }}>
                  But we managed to do it.
                </span>
              </Typography>
            </Box>
          </Box>
        </Container>
        <Container maxWidth="md">
          <Box
            sx={{
              display: "flex",
              borderTop: "1px solid #000",
              borderBottom: "1px solid #000",
              paddingTop: ".75rem",
              paddingBottom: ".75rem",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-start",
              }}
            >
              <Typography variant="body2">01</Typography>
            </Box>
            <Box>
              <Typography
                sx={{
                  textTransform: "uppercase",
                  fontFamily: "Inter",
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "1rem",
                  marginTop: ".5rem",
                  marginBottom: ".5rem",
                }}
              >
                Android app development
              </Typography>

            </Box>
            <Box
              sx={{
                flex: 1,
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <ArrowButton
                onClick={() => {
                  navigate("/services");
                }}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              paddingTop: ".75rem",
              paddingBottom: ".75rem",
            }}
          >
            <Box
              sx={{
                height: "2rem",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-start",
              }}
            >
              <Typography variant="body2">02</Typography>
            </Box>
            <Box>
              <Typography
                sx={{
                  textTransform: "uppercase",
                  fontFamily: "Inter",
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "1rem",
                  marginTop: ".5rem",
                  marginBottom: ".5rem",
                }}
              >
                ios app development
              </Typography>
            </Box>
            <Box
              sx={{
                flex: 1,
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <ArrowButton
                onClick={() => {
                  navigate("/services");
                }}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              borderTop: "1px solid #000",
              borderBottom: "1px solid #000",
              paddingTop: ".75rem",
              paddingBottom: "1.25rem",
            }}
          >
            <Box
              sx={{
                height: "2rem",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-start",
              }}
            >
              <Typography variant="body2">03</Typography>
            </Box>
            <Box>
              <Typography
                sx={{
                  textTransform: "uppercase",
                  fontFamily: "Inter",
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "1rem",
                  marginTop: ".5rem",
                  marginBottom: ".5rem",
                }}
              >
                Hybrid app development
              </Typography>
            </Box>
            <Box
              sx={{
                flex: 1,
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <ArrowButton
                onClick={() => {
                  navigate("/services");
                }}
              />
            </Box>
          </Box>
        </Container>
      </Container>
    </Fragment>
  );
};

export default Service;
