import React from "react";
import { Button } from "@mui/material";

const SecondaryButton = (props: any) => {
  return (
    <div>
      <Button
        style={{
          backgroundColor: "transparent",
          border: "1px solid #6D30BA",
          color: "#6D30BA",
          textTransform: "capitalize",
          padding: "0.5rem 1.25rem",
        }}
        onClick={props.onClick}
      >
        {props.title}
      </Button>
    </div>
  );
};

export default SecondaryButton;
