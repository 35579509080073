import { CssBaseline } from "@material-ui/core";
import { Box, Container, Typography } from "@mui/material";
import ContactForm from "../../molecule/ContactForm/ContactForm";
import Footer from "../../../components/molecule/Footer/Footer";
import ContactInfo from "../../molecule/ContactInfo/ContactInfo";

interface Style {
  maxWidth: any;
}

const Contact = (props: Style) => {
  const styleComp = (
    <div style={{ color: "#212121" }}>
      Ready to take your business to the
      <span
        style={{
          color: "#6D30B9",
          fontFamily: "Noto Serif",
          fontWeight: "bold",
        }}
      >
        {" "}
        next level
      </span>{" "}
    </div>
  );

  return (
    <>
      <CssBaseline />
      <Container
        maxWidth={props.maxWidth}
        sx={{ marginTop: "8rem", bgcolor: "#fff" }}
      >
        <Container maxWidth="md">
          <Box sx={{ marginTop: "4rem", marginBottom: "4rem" }}>
            <Typography
              variant="body1"
              sx={{
                fontFamily: "Inter",
                fontSize: "1rem",
              }}
            >
              Our contacts
            </Typography>
            <Typography variant="h1" sx={{ fontFamily: "Noto Serif", fontSize: '2rem' }}>
              Hello!
            </Typography>
            <Box
              sx={{
                fontFamily: "Inter",
                fontSize: {
                  lg: "70%",
                  md: "70%",
                  sm: "100%",
                  xs: "100%",
                },
              }}
            >
              <Typography
                sx={{
                  fontSize: {
                    lg: "2rem",
                    md: "2rem",
                    sm: "1.5rem",
                    xs: "1.5rem",
                  },
                  marginTop: "1.5rem",
                  width: {
                    sm: "100%",
                    md: "65%",
                    lg: "65%",
                    xl: "65%",
                  },
                }}
              >
                Could you be our newest friend? We certainly hope so. Don't
                hesitate to say hi and meet our team.
              </Typography>

            </Box>
          </Box>
        </Container>
      </Container>
      <Box sx={{ bgcolor: "#f4f4f4" }}>
        <ContactInfo />
      </Box>
      <Box
        sx={{
          backgroundColor: "#fff",
        }}
      >
        <ContactForm title="Still Curious?" description={styleComp} />
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box sx={{ borderBottom: "1px solid #e1e1e1", width: "80%" }}></Box>
        </Box>
      </Box>
      <Footer />
    </>
  );
};

export default Contact;
