import React, { Fragment } from "react";
import { CssBaseline, Container, Box, Typography } from "@mui/material";
import PortfolioCard from "../PortfolioCard/PortfolioCard";
import { makeStyles } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import SecondaryButton from "../../atom/SecondaryButton/SecondaryButton";

const useStyles = makeStyles((theme: any) => ({
  container: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
    },
  },
  flexStart: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "flex-end",
      flexDirection: "column",
    },
  },
  cardMargin: {
    [theme.breakpoints.down("sm")]: {
      marginTop: "0rem",
      marginBottom: "0rem",
    },
  },
}));

const PortfolioSection = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const PortfolioCardContent = [
    {
      margin: "0rem",
      title: "AHLSELL",
      description: "Leading distributors in the domain",
      backgroundImg: require("../../../assets/images/AlsellReact.webp"),
      content: {
        subHeading: "Leading distributors in the domain",
        mainHeading: "AHLSELL",
        bodyContent:
          "We have worked with the existing team to develop new features, improve current ones, and automate the process using CI-CD. Ahlsell is the leading distributor of installation goods, tools, and supplies in the Nordic region, with a focus on installation, construction, real estate management, industrial and power businesses, and government. Technologies used: Hybrid Mobile Application, React Native",
        firstImg: require("../../../assets/images/AhlsellHybridApp.webp"),
        secondImg: require("../../../assets/images/AhlsellReactNativeApp.webp"),
        thirdImg: require("../../../assets/images/AhlsellReactNativeiOSApp.webp"),
      },
    },
    {
      margin: "3rem",
      title: "H&M",
      description: "we welcome you to be yourself",
      backgroundImg: require("../../../assets/images/HNMiOSApp.webp"),
      content: {
        subHeading: "we welcome you to be yourself",
        mainHeading: "H&M",
        bodyContent:
          "We added new features, addressed all of the issues, and transformed it from Objective-C to Swift. An enthralling e-commerce adventure. Have you seen something you admire and it has inspired you? Using your own images or screen shots, VISUAL SEARCH allows you to discover what we have to offer. Technologies used: iOS App, Swift, SwiftUI, Objective-C, RxSwift",
        firstImg: require("../../../assets/images/HNMMakeiOSApp.webp"),
        secondImg: require("../../../assets/images/HNMNativeiOSApp.webp"),
        thirdImg: require("../../../assets/images/HNMSwiftUI.webp"),
      },
    },
    {
      margin: "0rem",
      title: "DOMETIC",
      description: "All about connectivity",
      backgroundImg: require("../../../assets/images/DometicHybridReactNativeApp.webp"),
      content: {
        subHeading: "All about connectivity",
        mainHeading: "DOMETIC",
        bodyContent:
          "We helped improved the existing app and also supported with many new features while refactoring the existing app. The work included hardware integration, custom protocols, MQTT, BLE communication. Technologies used: Hybrid App, React Native, Redux, MQTT, BLE, OTA",
        firstImg: require("../../../assets/images/DometicHybridApp.webp"),
        secondImg: require("../../../assets/images/DometicHybridReact.webp"),
        thirdImg: require("../../../assets/images/DometiciOSReactNativeApp.webp"),
      },
    },
    {
      margin: "3rem",
      title: "ELECTROLUX",
      description: "An application offers kitchen and laundry appliances",
      backgroundImg: require("../../../assets/images/ElectroluxWebApp.webp"),
      content: {
        subHeading: "An application offers kitchen and laundry appliances",
        mainHeading: "ELECTROLUX",
        bodyContent:
          "We contributed to an existing project As a solution provider, we improved the SDK and adopted the Test-Driven Development (TDD) technique, which is now universally acknowledged as an effective method for achieving great results. Our main focus was quality, hence we worked alot on unit tests, system tests, integration tests, and other test automation. We wrote separate PODs for different purposes, in order to have low maintenance and also keep the common classes together.",
        firstImg: require("../../../assets/images/ElectroluxProgrammer.webp"),
        secondImg: require("../../../assets/images/ElectroluxWebsite.webp"),
        thirdImg: require("../../../assets/images/ElectroluxWebsiteDevelopment.webp"),
      },
    },
  ];

  return (
    <Fragment>
      <CssBaseline />
      <Container disableGutters maxWidth="xl">
        <Container
          maxWidth="md"
          sx={{
            display: "flex",
            paddingTop: "2rem",
            paddingBottom: "2rem",
          }}
          className={classes.container}
        >
          <Box
            sx={{
              width: "40%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
            }}
          >
            <Typography
              variant="h2"
              sx={{
                fontSize: {
                  lg: "1rem",
                  md: "1rem",
                  sm: "1.5rem",
                  xs: "1.5rem",
                },
                fontWeight: 600,
                color: "#000",
                marginBottom: "1rem",
              }}
            >
              Portfolio
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
            className={classes.flexStart}
          >
            <Box sx={{ width: "88%" }}>
              <Typography
                variant="h3"
                sx={{
                  fontFamily: "Noto Serif",
                  marginBottom: "1rem",
                  fontSize: {
                    lg: "2rem",
                    md: "2rem",
                    sm: "1.25rem",
                    xs: "1.25rem",
                  },
                }}
              >
                A vision is created through creativity, its implementation
                requires power and courage.
              </Typography>
            </Box>
          </Box>
        </Container>
        <Container maxWidth="md">
          <Box
            sx={{ display: "flex", justifyContent: "space-between" }}
            className={classes.container}
          >
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
              }}
              className={classes.container}
            >
              {PortfolioCardContent.map((item, index) => {
                return (
                  <Box
                    sx={{
                      marginTop: {
                        xl: item.margin,
                        lg: item.margin,
                        md: item.margin,
                        sm: "0rem",
                        xs: "0rem",
                      },
                      marginBottom: "1.5rem",
                      width: {
                        xl: "40%",
                        lg: "40%",
                        md: "40%",
                        sm: "100%",
                        xs: "100%",
                      },
                    }}
                    className={classes.cardMargin}
                    key={index}
                  >
                    <PortfolioCard
                      title={item.title}
                      description={item.description}
                      backgroundImg={item.backgroundImg}
                      onClick={() =>
                        navigate("/works/portfolio", {
                          state: item,
                        })
                      }
                    />
                  </Box>
                );
              })}
            </Box>
          </Box>
          <Box
            sx={{
              marginTop: "2rem",
              marginBottom: "2rem",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <SecondaryButton
              onClick={() => {
                navigate("/works");
              }}
              title="Watch more"
            />
          </Box>
        </Container>
      </Container>
    </Fragment>
  );
};

export default PortfolioSection;
