import React from "react";
import { Helmet } from "react-helmet-async";
import Home from "../../components/organism/Home/Home";

const HomePage = () => {
  return (
    <div>
      <Helmet>
        <title>Mobile app development company in stockholm-sthlmtechpartner</title>
        <meta name="description" content="SthlmTechPartner, a top mobile app development company in Stockholm,Sweden can help you turn your app idea into a reality. Contact us today to get started! " />
        <link rel="canonical" href="/" />
      </Helmet>
      <Home />
    </div>
  );
};

export default HomePage;
