import React from "react";
import { Box, Container, Typography } from "@mui/material";
import ProdutDesigner from "../../../assets/images/ProductDesigner.webp";
import OurPartners from "../../molecule/OurPartners/OurPartners";
import ContactForm from "../../molecule/ContactForm/ContactForm";
import Footer from "../../molecule/Footer/Footer";
import { makeStyles } from "@material-ui/core";
import OurTeam from "../../molecule/OurTeam/OurTeam";

const useStyles = makeStyles((theme: any) => ({
  container: {
    [theme.breakpoints.down("630")]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  },
  imgWidth: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: "1rem",
      borderRadius: "10px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "83%",
      borderRadius: "0 10px 10px 0",
    },
  },
}));

interface Style {
  maxWidth: any;
}

const AboutUs = (props: Style) => {
  const classes = useStyles();

  const styleComp = (
    <div style={{ color: "#212121" }}>
      Tell us your problem and get the
      <span
        style={{
          color: "#6D30B9",
          fontFamily: "Noto Serif",
          fontWeight: "bold",
        }}
      >
        {" "}
        best services for you
      </span>{" "}
    </div>
  );

  return (
    <>
      <Container
        maxWidth={props.maxWidth}
        disableGutters
        sx={{
          paddingTop: "4rem",
          paddingBottom: "2rem",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Container
          maxWidth="md"
          sx={{
            marginTop: "4rem",
            marginBottom: {
              xl: "4rem",
              lg: "4rem",
              md: "4rem",
              sm: "1rem",
              xs: "1rem",
            },
          }}
        >
          <Box>
            <Typography variant="body1">Ready to make it happen</Typography>
            <Typography variant="h1" sx={{ fontFamily: "Noto Serif", fontSize: '2rem' }}>
              About Us
            </Typography>
          </Box>
          <Typography
            variant="h2"
            sx={{
              fontFamily: "Inter",
              width: "88%",
              marginTop: "3rem",
              marginBottom: "1rem",
              fontWeight: 400,
              fontSize: {
                lg: "2rem",
                md: "2rem",
                sm: "1.5rem",
                xs: "1.5rem",
              },
            }}
          >
            We are a leading Mobile app development company based in Stockholm, Sweden. We create
            social impact!
          </Typography>
        </Container>
        <Container maxWidth="md">
          <Box>
            <img
              src={ProdutDesigner}
              alt="product designer"
              className={classes.imgWidth}
              width="100%"
              height="71.8%"
              title="product designer"
              loading="lazy"
            />
          </Box>
        </Container>
        <Container
          maxWidth="md"
          sx={{
            display: "flex",
            justifyContent: {
              sm: "flex-start",
              md: "flex-start",
              lg: "flex-start",
              xl: "flex-start",
            },
          }}
        >
          <Typography
            variant="h2"
            sx={{
              fontFamily: "Inter",
              width: {
                sm: "100%",
                md: "83%",
                lg: "83%",
                xl: "85%",
              },
              marginTop: "3rem",
              marginBottom: "1rem",
              fontSize: {
                lg: "2rem",
                md: "2rem",
                sm: "1.5rem",
                xs: "1.5rem",
              },
              fontWeight: 400
            }}
          >
            Sthlmtechpartner is a passionate advocate for Gender Equality
            issues, as our core business revolves around app development services
            and supporting females in the IT and Tech industry.
          </Typography>
        </Container>
        <Container
          sx={{
            width: "100%",
            marginTop: "3rem",
            display: "flex",
            justifyContent: "space-between",
          }}
          maxWidth="md"
          className={classes.container}
        >
          <Box
            sx={{
              paddingLeft: ".5rem",
              paddingRight: ".5rem",
            }}
          >
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "4rem",
                display: "flex",
                alightItems: "center",
                justifyContent: "center",
                fontFamily: "Noto Serif",
              }}
            >
              112+
            </Typography>
            <Typography
              variant="body1"
              sx={{
                display: "flex",
                alightItems: "center",
                justifyContent: "center",
                fontFamily: "Inter",
                textAlign: "center",
                fontSize: "1.15rem",
              }}
            >
              Satisfied Clients
            </Typography>
          </Box>
          <Box sx={{ paddingLeft: ".5rem", paddingRight: ".5rem" }}>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "4rem",
                display: "flex",
                alightItems: "center",
                justifyContent: "center",
                fontFamily: "Noto Serif",
              }}
            >
              138+
            </Typography>
            <Typography
              variant="body1"
              sx={{
                display: "flex",
                alightItems: "center",
                justifyContent: "center",
                fontFamily: "Inter",
                textAlign: "center",
                fontWeight: "500",
                fontSize: "1.15rem",
              }}
            >
              Clear Projects
            </Typography>
          </Box>
          <Box sx={{ paddingLeft: ".5rem", paddingRight: ".5rem" }}>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "4rem",
                display: "flex",
                alightItems: "center",
                justifyContent: "center",
                fontFamily: "Noto Serif",
              }}
            >
              347+
            </Typography>
            <Typography
              variant="body1"
              sx={{
                display: "flex",
                alightItems: "center",
                justifyContent: "center",
                fontFamily: "Inter",
                textAlign: "center",
                fontWeight: "500",
                fontSize: "1.15rem",
              }}
            >
              Cups of coffee
            </Typography>
          </Box>
          <Box sx={{ paddingLeft: ".5rem", paddingRight: ".5rem" }}>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "4rem",
                display: "flex",
                alightItems: "center",
                justifyContent: "center",
                fontFamily: "Noto Serif",
              }}
            >
              1912+
            </Typography>
            <Typography
              variant="body1"
              sx={{
                display: "flex",
                alightItems: "center",
                justifyContent: "center",
                fontFamily: "Inter",
                textAlign: "center",
                fontSize: "1.15rem",
                fontWeight: "500",
              }}
            >
              Days of work
            </Typography>
          </Box>
        </Container>
      </Container>
      <Container>
        <OurTeam />
      </Container>
      <OurPartners />
      <Box sx={{ backgroundColor: "#f4f4f4" }}>
        <ContactForm
          title="Interested in working with us?"
          description={styleComp}
        />
      </Box>
      <Footer />
    </>
  );
};

export default AboutUs;
