export const PortfolioCardContent = [
    {
        margin: "0rem",
        title: "C MORE",
        description: "An IOS application for entertainment companies",
        backgroundImg: require("../../../assets/images/CMoreiPhoneAppDevelopment.webp"),
        content: {
            subHeading: "An IOS application for entertainment companies",
            mainHeading: "C MORE",
            bodyContent:
                "Contributed new features to existing app and also helped and refactored the existing code. Technologies used: Objective-C , Pods, MVMM-C",
            firstImg: require("../../../assets/images/CmoreiphoneDevelopment.webp"),
            secondImg: require("../../../assets/images/CmoreiphoneAppDeveloper.webp"),
            thirdImg: require("../../../assets/images/CmoreiOSDevelopmentOnWindows.webp"),
        },
    },
    {
        margin: "6rem",
        title: "Expressen Tidning",
        description: "An IOS application for news",
        backgroundImg: require("../../../assets/images/expressionWebDevelopment.webp"),
        content: {
            subHeading: "An IOS application for news",
            mainHeading: "Expressen Tidning",
            bodyContent:
                "Contributed new features to existing app and also helped and refactored the existing code. Technologies used: Objective-C , MVC, Pods",
            firstImg: require("../../../assets/images/ExpressenTidningIphoneApp.webp"),
            secondImg: require("../../../assets/images/ExpressenTidningIphoneApplicationiPhoneMobile.webp"),
            thirdImg: require("../../../assets/images/ExpressenTidningIphoneApplication.webp"),
        },
    },
];