import React from "react";
import { Container, Box, Typography } from "@mui/material";
import OurTeamCard from "../OurTeamCard/OurTeamCard";

const blogCardContent = [
  {
    backgroundImage: require("../../../assets/images/RashdaniOSAppDeveloper.webp"),
    name: "Rashdan Natiq",
    designation: "Chief Executive Officer",
  },
  {
    backgroundImage: require("../../../assets/images/AsadKazmiWebDeveloper.webp"),
    name: "Asad Kazmi",
    designation: "Chief Technology Officer",
  },
  {
    backgroundImage: require("../../../assets/images/FarrukhJavaDeveloper.webp"),
    name: "Farrukh Mahmood",
    designation: "Java Developer",
  },
  {
    backgroundImage: require("../../../assets/images/HassanWebDeveloper.webp"),
    name: "Hassan Azhar",
    designation: "Web Tech Lead",
  },
  {
    backgroundImage: require("../../../assets/images/SultanIphoneAppDeveloper.webp"),
    name: "Sultan Ali",
    designation: "Mobile Tech Lead",
  },
  {
    backgroundImage: require("../../../assets/images/FaizReactNativeDeveloper.webp"),
    name: "Muhammad Faiz",
    designation: "Mobile Developer",
  },
  {
    backgroundImage: require("../../../assets/images/AliIosMobileDeveloper.webp"),
    name: "Ali Subhani",
    designation: "iOS Developer",
  },
  {
    backgroundImage: require("../../../assets/images/TayyabaWebDeveloper.webp"),
    name: "Tayyaba",
    designation: "Project Manager/QA",
  },
  {
    backgroundImage: require("../../../assets/images/AyeshaIphoneDevelopment.webp"),
    name: "Aisha Rauf",
    designation: "Graphic Designer",
  },
  {
    backgroundImage: require("../../../assets/images/KhairMobileDeveloper.webp"),
    name: "Khair ul Burria",
    designation: "Mobile Developer",
  },
  {
    backgroundImage: require("../../../assets/images/SamanLaravelDeveloper.webp"),
    name: "Saman Shahid",
    designation: "Laravel Developer",
  },
  {
    backgroundImage: require("../../../assets/images/AqsaAndroidDeveloper.webp"),
    name: "Aqsa Azam",
    designation: "Android Developer",
  },
  {
    backgroundImage: require("../../../assets/images/SheezaJavaDeveloper.webp"),
    name: "Sheeza Kanwal",
    designation: "Java Developer",
  },
  {
    backgroundImage: require("../../../assets/images/ShahzadiOSDeveloper.webp"),
    name: "Shahzad Rasool",
    designation: "iOS Developer",
  },
  {
    backgroundImage: require("../../../assets/images/WaqarSwiftUi.webp"),
    name: "Waqar Jutt",
    designation: "iOS Developer",
  },
  {
    backgroundImage: require("../../../assets/images/ShahzaibReactDeveloper.webp"),
    name: "Shahzaib Zafar",
    designation: "Mobile Developer",
  },
  {
    backgroundImage: require("../../../assets/images/UsmanReactNativeDeveloper.webp"),
    name: "Muhammad Usman",
    designation: "Mobile Developer",
  },
];
const OurTeam = () => {

  return (
    <div>
      <Container disableGutters maxWidth="xl">
        <Container
          maxWidth="md"
          sx={{
            paddingTop: "4rem",
            paddingBottom: "4rem",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: "2.2rem",
              fontFamily: "Noto Serif",
              marginBottom: "2rem",
            }}
          >
            Meet Our Team
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            {blogCardContent.map((item, index) => {
              return (

                <Box
                  sx={{
                    marginBottom: "2rem",
                  }}
                  key={index}
                >
                  <OurTeamCard
                    sx={{
                      fontSize: "1.25rem",
                      color: "#fff",
                      fontWeight: 540,
                      lineHeight: "1.5rem",
                    }}
                    width="9.5rem"
                    height="9.5rem"
                    backgroundImage={item.backgroundImage}
                  />
                  <Typography
                    variant="h3"
                    style={{
                      fontWeight: 540,
                      marginTop: ".5rem",
                      fontSize: "1rem",
                    }}
                  >
                    {item.name}
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{
                      fontSize: ".75rem",
                    }}
                  >
                    {item.designation}
                  </Typography>
                </Box>
              );
            })}
          </Box>
        </Container>
      </Container>
    </div>
  );
};

export default OurTeam;
