import React, { Fragment } from "react";
import { CssBaseline, Container, Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import LogoType from "../../../assets/other/LogoType.webp";
import PrimaryButton from "../../atom/PrimaryButton/PrimaryButton";
import { makeStyles } from "@material-ui/core";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme: any) => ({
  contactMobileView: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
    },
  },
  linkToggle: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  logoSmToggle: {
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  workWithUs: {
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      maxWidth: "40%",
    },
  },
}));

const Footer = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <Fragment>
      <CssBaseline />
      <Container maxWidth="xl">
        <Box
          sx={{
            paddingTop: "2rem",
            fontSize: "2rem",
            fontWeight: 600,
          }}
          className={classes.logoSmToggle}
        >
          <Link to="/">
            <img src={LogoType} alt="logo" width="106.66px" height="39px" title="logo" loading="lazy" />
          </Link>
        </Box>
        <Container
          maxWidth="md"
          disableGutters
          sx={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "flex-start",
            justifyContent: "space-between",
            paddingTop: "2rem",
            paddingBottom: "2rem",
          }}
        >

          <Box
            sx={{
              display: { sm: "none", lg: "block" },
              flexDirection: "column",
              justifyContent: "flex-start",
              // height: "100%",
              padding: "1rem",
            }}
            className={classes.linkToggle}
          >

            <Link to="/">
              <img src={LogoType} alt="logo"
                width="106.66px" height="39px" title="logo" loading="lazy"
                style={{ marginBottom: ".75rem" }} />
            </Link>
            <Typography
              variant="h4"
              sx={{
                fontSize: "1rem",
                fontWeight: 600,
                color: "#000",
                marginBottom: "1rem",
                fontFamily: "Noto Serif",
                textDecoration: "underline",
              }}
            >
              <Link style={{
                textDecoration: "none",
                color: "#000",
              }}

                to={'//www.facebook.com/sthlmtechpartner/'}
                target={"_blank"}
              >
                Facebook
              </Link>
            </Typography>
            <Typography
              variant="h4"
              sx={{
                fontSize: "1rem",
                fontWeight: 600,
                color: "#000",
                marginBottom: "1rem",
                fontFamily: "Noto Serif",
                textDecoration: "underline",
              }}
            >
              <Link
                style={{
                  textDecoration: "none",
                  color: "#000",
                }}
                to={'//mobile.twitter.com/sthlmtechprtnr'}
                target={"_blank"}
              >

                Twitter
              </Link>
            </Typography>
            <Typography
              variant="h4"
              sx={{
                fontSize: "1rem",
                fontWeight: 600,
                color: "#000",
                marginBottom: "1rem",
                fontFamily: "Noto Serif",
                textDecoration: "underline",
              }}
            >
              <Link
                style={{
                  textDecoration: "none",
                  color: "#000",
                }}
                to={'//www.instagram.com/sthlmtechpartner.official/?hl=en'}
                target={"_blank"}
              >
                Instagram
              </Link>
            </Typography>
            <Typography
              variant="h4"
              sx={{
                fontSize: "1rem",
                fontWeight: 600,
                color: "#000",
                marginBottom: "1rem",
                fontFamily: "Noto Serif",
                textDecoration: "underline",
              }}
            >
              <Link
                style={{
                  textDecoration: "none",
                  color: "#000",
                }}
                to={'//www.linkedin.com/company/sthlm-tech-partner'}
                target={"_blank"}
              >
                LinkedIn
              </Link>
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              flexDirection: "column",
              height: "100%",
              padding: "1rem 0",
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontSize: "1rem",
                fontWeight: 600,
                color: "#000",
                marginBottom: "2rem",
                fontFamily: "Noto Serif",
              }}
            >
              Menu
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: ".5rem" }}>
              <Link
                to="/"
                style={{
                  textDecoration: "none",
                  color: "#000",
                }}
              >
                Home
              </Link>
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: ".5rem" }}>
              <Link
                to="/about"
                style={{
                  textDecoration: "none",
                  color: "#000",
                }}
              >
                About
              </Link>
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: ".5rem" }}>
              <Link
                to="/services"
                style={{
                  textDecoration: "none",
                  color: "#000",
                }}
              >
                Services
              </Link>
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: ".5rem" }}>
              <Link
                to="/works"
                style={{
                  textDecoration: "none",
                  color: "#000",
                }}
              >
                Works
              </Link>
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: ".5rem" }}>
              <Link
                to="/blogs"
                style={{
                  textDecoration: "none",
                  color: "#000",
                }}
              >
                Blogs
              </Link>
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              flexDirection: "column",
              padding: "1rem 0",
            }}
          >
            <Typography
              variant="h6"
              sx={{
                fontSize: "1rem",
                fontWeight: 600,
                color: "#000",
                marginBottom: "2rem",
                fontFamily: "Noto Serif",
              }}
            >
              Services
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: ".5rem" }}>
              Android App Development
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: ".5rem" }}>
              IOS App Development
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: ".5rem" }}>
              Hybrid App Development
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              flexDirection: "column",
              padding: "1rem 0",
            }}
            className={classes.workWithUs}
          >
            <Typography
              variant="h6"
              sx={{
                fontSize: "1rem",
                fontWeight: 600,
                color: "#000",
                marginBottom: "2rem",
                fontFamily: "Noto Serif",
              }}
            >
              Want to work with us on your next project? We’d love to hear from
              you.
            </Typography>
            <Typography variant="body1">0705888806</Typography>
            <Link to="#">rashdan@sthlmtechpartner.com</Link>
            <Box
              sx={{
                marginTop: "2rem",
                width: {
                  xs: "100%",
                  sm: "100%",
                  md: "40%",
                  lg: "40%",
                  xl: "40%",
                },
              }}
            >
              <PrimaryButton
                title="Work With Us"
                onClick={() => navigate("/contacts")}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: { xs: "flex ", sm: "flex", md: "none", lg: "none" },
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Typography
              variant="h6"
              sx={{
                fontSize: "1rem",
                fontWeight: 600,
                color: "#000",
                fontFamily: "Noto Serif",
                textDecoration: "underline",
              }}
            >
              <Link style={{
                textDecoration: "none",
                color: "#000",
              }}

                to={'//www.facebook.com/sthlmtechpartner/'}
                target={"_blank"}
              >
                Facebook
              </Link>
            </Typography>
            <Typography
              variant="h6"
              sx={{
                fontSize: "1rem",
                fontWeight: 600,
                color: "#000",
                fontFamily: "Noto Serif",
                textDecoration: "underline",
              }}
            >
              <Link
                style={{
                  textDecoration: "none",
                  color: "#000",
                }}
                to={'//mobile.twitter.com/sthlmtechprtnr'}
                target={"_blank"}
              >

                Twitter
              </Link>
            </Typography>
            <Typography
              variant="h6"
              sx={{
                fontSize: "1rem",
                fontWeight: 600,
                color: "#000",
                fontFamily: "Noto Serif",
                textDecoration: "underline",
              }}
            >
              <Link
                style={{
                  textDecoration: "none",
                  color: "#000",
                }}
                to={'//www.instagram.com/sthlmtechpartner.official/?hl=en'}
                target={"_blank"}
              >
                Instagram
              </Link>
            </Typography>
            <Typography
              variant="h6"
              sx={{
                fontSize: "1rem",
                fontWeight: 600,
                color: "#000",
                fontFamily: "Noto Serif",
                textDecoration: "underline",
              }}
            >
              <Link
                style={{
                  textDecoration: "none",
                  color: "#000",
                }}
                to={'//www.linkedin.com/company/sthlm-tech-partner'}
                target={"_blank"}
              >
                LinkedIn
              </Link>
            </Typography>
          </Box>
        </Container>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="body1"
            sx={{ marginBottom: "1rem", textAlign: "center" }}
          >
            Copyright © 2023 Sthlmtechpartner. All Rights Reserved.
          </Typography>
        </Box>
      </Container>
    </Fragment>
  );
};

export default Footer;
