import { Box, Container, Typography } from "@mui/material";
import ContactForm from "../../molecule/ContactForm/ContactForm";
import Footer from "../../molecule/Footer/Footer";
import BlogCard from "../../molecule/BlogCard/BlogCard";
import ArrowButton from "../../atom/ArrowButton/ArrowButton";
import { Helmet } from "react-helmet-async";

const serviceCard = [
  {
    title: "Android App Development",
    description:
      "We bring your ideas to life. Our Android app developers team is driven to produce creative solutions and thrilling android apps utilizing native languages like Java and Kotlin. We ensure scalability using cloud backend solution, Our feature-rich android apps provide an exceptional user experience.",
    backgroundImage: require("../../../assets/images/AndroidAppsDevelopment.webp"),
    arrowButton: <ArrowButton />,
  },
  {
    title: "IOS App Development",
    description:
      "Our vibrant iOS programming team provides outstanding services, from development to deployment. We are comfortable with working in Swift, SwiftUI and Objective-C to create the comprehensive applications, which are then published to the App Store for users to download.",
    backgroundImage: require("../../../assets/images/iOSAppsDevelopment.webp"),
    arrowButton: <ArrowButton />,
  },
  {
    title: "Hybrid App Development",
    description:
      "We have built amazing hybrid apps both in React Native and Flutter. Hybrid apps is another great option to built amazing app. Our talented mobile app developers offer great services and deliver scaleable and sustainable apps. We also provide responsive maintenance support for 24/7",
    backgroundImage: require("../../../assets/images/HybridAppsDevelopment.webp"),
    arrowButton: <ArrowButton />,
  },
];

const Services = () => {

  const styleComp = (
    <div style={{ color: "#212121" }}>
      Tell us your problem and get the
      <span
        style={{
          color: "#6D30B9",
          fontFamily: "Noto Serif",
          fontWeight: "bold",
        }}
      >
        {" "}
        best services for you
      </span>{" "}
    </div>
  );

  return (
    <>
      <Helmet>
        <title>Services</title>
        <meta name="description" content="Our vibrant iOS programming team provides outstanding services, from development to deployment. We are comfortable with working in Swift, SwiftUI and Objective-C." />
        <link rel="canonical" href="/services" />
      </Helmet>
      <Container disableGutters maxWidth="xl">
        <Container
          maxWidth="md"
          sx={{
            paddingTop: "4rem",
            paddingBottom: "2rem",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              marginTop: "4rem",
              marginBottom: {
                xl: "4rem",
                lg: "4rem",
                md: "4rem",
                sm: "1rem",
                xs: "1rem",
              },
            }}
          >
            <Box sx={{ marginBottom: "1rem" }}>
              <Typography variant="body1">Services</Typography>
              <Typography variant="h1" sx={{ fontFamily: "Noto Serif", fontSize: '2rem' }}>
                Meet your needs
              </Typography>
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexWrap: "wrap",
              }}
            >
              {serviceCard.map((item, index) => {
                return (
                  <Box
                    sx={{
                      marginBottom: {
                        xs: "2rem",
                        sm: "2rem",
                        md: "2rem",
                        lg: "0rem",
                        xl: "0rem",
                      },
                    }}
                    key={index}
                  >
                    <BlogCard
                      sx={{
                        fontSize: ".85rem",
                        color: "#fff",
                        lineHeight: "1.25rem",
                      }}
                      title={item.title}
                      description={item.description}
                      backgroundImage={item.backgroundImage}
                      arrowButton={item.arrowButton}
                      width="17.5rem"
                      height="20rem"
                      onClick={() => []}
                    />
                  </Box>
                );
              })}
            </Box>
          </Box>
        </Container>
      </Container>
      <Box sx={{ backgroundColor: "#f4f4f4" }}>
        <ContactForm
          title="Interested in working with us?"
          description={styleComp}
        />
      </Box>
      <Footer />
    </>
  );
};

export default Services;
