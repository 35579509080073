import React from "react";
import { Box } from "@mui/material";


export interface TeamContent {
  sx: any;
  backgroundImage: string;
  width: any;
  height: any;
}

const OurTeamCard = (props: TeamContent) => {

  return (
    <div>
      <Box
        sx={{
          backgroundImage: `url(${props.backgroundImage})`,
          width: {
            xs: "8rem",
            sm: "8rem",
            md: props.width,
            lg: props.width,
            xl: props.width,
          },
          height: {
            xs: "8rem",
            sm: "8rem",
            md: props.height,
            lg: props.height,
            xl: props.height,
          },
          backgroundSize: "cover",
          backgroundPosition: "center",
          zIndex: -1,
          padding: "2rem",
        }}
      ></Box>
    </div>
  );
};

export default OurTeamCard;
