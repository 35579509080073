import React from "react";
import { Helmet } from "react-helmet-async";
import Portfolio from "../../components/organism/Portfolio/Portfolio";

const PortfolioPage = () => {
  return (
    <div>
      <Helmet>
        <title>Portfolios</title>
        <meta name="description" content="We bring your ideas to life. Our Android app developers team is driven to produce creative solutions and thrilling android apps utilizing native languages." />
        <link rel="canonical" href="/works" />
      </Helmet>
      <Portfolio maxWidth="false" />
    </div>
  );
};

export default PortfolioPage;
