import React, { Fragment } from "react";
import { CssBaseline, Container, Box, Typography } from "@mui/material";
import Navbar from "../../molecule/Navbar/Navbar";
import PortfolioCard from "../../molecule/PortfolioCard/PortfolioCard";
import ContactForm from "../../molecule/ContactForm/ContactForm";
import Footer from "../../molecule/Footer/Footer";
import { makeStyles } from "@material-ui/core";
import { useNavigate, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { PortfolioCardContent } from "./PortfolioContent";

const useStyles = makeStyles((theme: any) => ({
  container: {
    [theme.breakpoints.down("630")]: {
      display: "flex",
      flexDirection: "column",
    },
  },
  portfolioSingleType: {
    [theme.breakpoints.down("630")]: {
      display: "flex",
      flexDirection: "column",
    },
  },
  displayNone: {
    [theme.breakpoints.down("630")]: {
      display: "none",
    },
  },
  secondImg: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      width: "70%",
    },
  },
}));

export interface Data {
  content: {
    mainHeading: string;
    subHeading: string;
    bodyContent: string;
    firstImg: string;
    secondImg: string;
    thirdImg: string;
  };
}

const PortfolioSingle = (props: any) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const location = useLocation().state as Data;

  const myDescription = (
    <p>
      Tell us your problem and get the{" "}
      <span style={{ color: "#6D30BA", fontWeight: "bold" }}>
        best services for you
      </span>
    </p>
  );


  return (
    <Fragment>
      <Helmet>
        <title>Portfolio</title>
        <meta name="description" content="We have worked with the existing team to develop new features, improve current ones, and automate the process using CI-CD." />
        <link rel="canonical" href="/works/portfolio" />
      </Helmet>
      <CssBaseline />
      <Container
        disableGutters
        maxWidth={props.width}
        sx={{
          paddingTop: "8rem",
          paddingBottom: "4rem",
        }}
      >
        <Container
          maxWidth="md"
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Navbar />
          <Box sx={{ display: "flex" }} className={classes.portfolioSingleType}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: {
                  sm: "80%",
                  md: "50%",
                  lg: "50%",
                  xl: "50%",
                },
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  fontSize: {
                    lg: "1rem",
                    md: "1rem",
                    sm: "1.75rem",
                    xs: "1.75rem",
                  },
                }}
              >
                {location.content.subHeading}
              </Typography>
              <Typography
                variant="h1"
                sx={{ fontFamily: "Noto Serif", marginBottom: "3rem", fontSize: '2rem' }}
              >
                {location.content.mainHeading}
              </Typography>
            </Box>
            <Box
              sx={{
                width: {
                  xl: "60%",
                  lg: "60%",
                  md: "60%",
                  sm: "100%",
                },
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Noto Serif",
                  fontSize: {
                    xl: "2rem",
                    lg: "2rem",
                    md: "2rem",
                    sm: "1.5rem",
                    xs: "1.5rem",
                  },
                }}
              >
                {location.content.bodyContent}
              </Typography>
            </Box>
          </Box>
          <Container maxWidth="lg" disableGutters>
            <Box sx={{ marginBottom: "2rem" }} className={classes.container}>
              <Box
                sx={{
                  marginTop: "3rem",
                  width: {
                    xm: "100%",
                    sm: "100%",
                    md: "70%",
                    lg: "70%",
                  },
                }}
                className={classes.container}
              >
                <img
                  src={location.content.firstImg}
                  width="100%"
                  height="100%"
                  title="iOS Native App"
                  loading="lazy"
                  alt="iOS Native App"
                />
              </Box>
              <Box
                sx={{
                  marginTop: "3rem",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
                className={classes.container}
              >
                <img
                  src={location.content.secondImg}
                  className={classes.secondImg}
                  width="100%"
                  height="100%"
                  title="Hybrid React Native App"
                  loading="lazy"
                  alt="Hybrid React Native App"
                />
              </Box>
              <Box
                sx={{
                  marginTop: "3rem",
                  width: {
                    xm: "100%",
                    sm: "100%",
                    md: "70%",
                    lg: "70%",
                  },
                }}
                className={classes.container}
              >
                <img
                  src={location.content.thirdImg}
                  width="100%"
                  height="100%"
                  title="Swift Ui development"
                  loading="lazy"
                  alt="Swift Ui development"
                />
              </Box>
            </Box>
            <Box className={classes.container} sx={{ marginBottom: "1rem" }}>
              <Typography
                variant="h2"
                sx={{ fontFamily: "Noto Serif", marginBottom: "1rem", fontSize: '2rem' }}
              >
                More works
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
                className={classes.container}
              >
                {PortfolioCardContent.map((item, index) => {
                  return (
                    <Box
                      sx={{
                        marginTop: {
                          xl: item.margin,
                          lg: item.margin,
                          md: item.margin,
                          sm: "0rem",
                          xs: "0rem",
                        },
                        marginBottom: "1rem",
                        width: {
                          xl: "40%",
                          lg: "40%",
                          md: "40%",
                          sm: "100%",
                          xm: "100%",
                        },
                      }}
                      key={index}
                    >
                      <PortfolioCard
                        title={item.title}
                        description={item.description}
                        backgroundImg={item.backgroundImg}
                        onClick={() =>
                          navigate("/works/portfolio", {
                            state: item,
                          })
                        }
                      />
                    </Box>

                  );
                })}
              </Box>
            </Box>
          </Container>
        </Container>
        <Box sx={{ backgroundColor: "#f4f4f4" }}>
          <ContactForm
            title="Interested in working with us?"
            description={myDescription}
          />
        </Box>
        <Footer />
      </Container>
    </Fragment>
  );
};

export default PortfolioSingle;
