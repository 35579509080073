export const PortfolioCardContent = [
    {
        margin: "0rem",
        title: "AHLSELL",
        description: "Leading distributors in the domain",
        backgroundImg: require("../../../assets/images/AlsellReact.webp"),
        content: {
            subHeading: "Leading distributors in the domain",
            mainHeading: "AHLSELL",
            bodyContent:
                "We have worked with the existing team to develop new features, improve current ones, and automate the process using CI-CD. Ahlsell is the leading distributor of installation goods, tools, and supplies in the Nordic region, with a focus on installation, construction, real estate management, industrial and power businesses, and government. Technologies used: hybrid mobile application, React Native",
            firstImg: require("../../../assets/images/AhlsellHybridApp.webp"),
            secondImg: require("../../../assets/images/AhlsellReactNativeApp.webp"),
            thirdImg: require("../../../assets/images/AhlsellReactNativeiOSApp.webp"),
        },
    },
    {
        margin: "3rem",
        title: "H&M",
        description: "we welcome you to be yourself",
        backgroundImg: require("../../../assets/images/HNMiOSApp.webp"),
        content: {
            subHeading: "we welcome you to be yourself",
            mainHeading: "H&M",
            bodyContent:
                "We added new features, addressed all of the issues, and transformed it from Objective-C to Swift. An enthralling e-commerce adventure. Have you seen something you admire and it has inspired you? Using your own images or screen shots, VISUAL SEARCH allows you to discover what we have to offer. Technologies used: iOS App, Swift, SwiftUI, Objective-C, RxSwift",
            firstImg: require("../../../assets/images/HNMMakeiOSApp.webp"),
            secondImg: require("../../../assets/images/HNMNativeiOSApp.webp"),
            thirdImg: require("../../../assets/images/HNMSwiftUI.webp"),
        },
    },
    {
        margin: "0rem",
        title: "DOMETIC",
        description: "All about connectivity",
        backgroundImg: require("../../../assets/images/DometicHybridReactNativeApp.webp"),
        content: {
            subHeading: "All about connectivity",
            mainHeading: "DOMETIC",
            bodyContent:
                "We helped improved the existing app and also supported with many new features while refactoring the existing app. The work included hardware integration, custom protocols, MQTT, BLE communication. Technologies used: Hybrid App, React Native, Redux, MQTT, BLE, OTA",
            firstImg: require("../../../assets/images/DometicHybridApp.webp"),
            secondImg: require("../../../assets/images/DometicHybridReact.webp"),
            thirdImg: require("../../../assets/images/DometiciOSReactNativeApp.webp"),
        },
    },
    {
        margin: "3rem",
        title: "ELECTROLUX",
        description: "An application offers kitchen and laundry appliances",
        backgroundImg: require("../../../assets/images/ElectroluxWebApp.webp"),
        content: {
            subHeading: "An application offers kitchen and laundry appliances",
            mainHeading: "ELECTROLUX",
            bodyContent:
                "We contributed to an existing project As a solution provider, we improved the SDK and adopted the Test-Driven Development (TDD) technique, which is now universally acknowledged as an effective method for achieving great results. Our main focus was quality, hence we worked alot on unit tests, system tests, integration tests, and other test automation. We wrote separate PODs for different purposes, in order to have low maintenance and also keep the common classes together.",
            firstImg: require("../../../assets/images/ElectroluxProgrammer.webp"),
            secondImg: require("../../../assets/images/ElectroluxWebsite.webp"),
            thirdImg: require("../../../assets/images/ElectroluxWebsiteDevelopment.webp"),
        },
    },
    {
        margin: "0",
        title: "Folksam",
        description: "An application about insurance services",
        backgroundImg: require("../../../assets/images/FolksamiOSApp.webp"),
        content: {
            subHeading: "An application about insurance services",
            mainHeading: "Folksam",
            bodyContent:
                "We started the project from scratch. Broke down the monolith app into smaller chunks and made Swift Packages for different modules. We then used SwiftUI to build the app together with Combine. Implemented many new features and flows. Technologies used: Swift iOS, Swift, SwiftUI, Combine, SPM",
            firstImg: require("../../../assets/images/FolksamiOSSwift.webp"),
            secondImg: require("../../../assets/images/FolksamiOSSwiftUi.webp"),
            thirdImg: require("../../../assets/images/FolksamSwift.webp"),
        },
    },
    {
        margin: "3rem",
        title: "Taxi Stockholm",
        description: "An application for online Taxi booking",
        backgroundImg: require("../../../assets/images/TaxiSthlmiOSApp.webp"),
        content: {
            subHeading: "An application for online Taxi booking",
            mainHeading: "Taxi Stockholm",
            bodyContent:
                "We started the project from scratch. Ported some of the features from the legacy app, but mainly wrote the app from scratch and introduced a new , better looking app. Technologies used: iPhone App, Objective-C , Pods, MVC",
            firstImg: require("../../../assets/images/TaxiSthlmIphone.webp"),
            secondImg: require("../../../assets/images/TaxiSthlmIphoneDevelopment.webp"),
            thirdImg: require("../../../assets/images/TaxiSthlmSwift.webp"),
        },
    },
    {
        margin: "0",
        title: "Storytel",
        description: "An IOS application for audiobooks & ebooks",
        backgroundImg: require("../../../assets/images/StoryteliOSAppDevelopment.webp"),
        content: {
            subHeading: "An IOS application for audiobooks & ebooks",
            mainHeading: "Storytel",
            bodyContent:
                "Contributed new features to existing app and also helped and refactored the existing code. Technologies used: iPhone Mobile Application, Objective-C , Carthage, Viper ",
            firstImg: require("../../../assets/images/storyTeliOSApp.webp"),
            secondImg: require("../../../assets/images/storyTeliOSAppDevloper.webp"),
            thirdImg: require("../../../assets/images/storyTeliOSAppDevlopment.webp"),
        },
    },
    {
        margin: "3rem",
        title: "C MORE",
        description: "An IOS application for entertainment companies",
        backgroundImg: require("../../../assets/images/CMoreiPhoneAppDevelopment.webp"),
        content: {
            subHeading: "An IOS application for entertainment companies",
            mainHeading: "C MORE",
            bodyContent:
                "Contributed new features to existing app and also helped and refactored the existing code. Technologies used: iOS App, Objective-C , Pods, MVMM-C",
            firstImg: require("../../../assets/images/CmoreiOSDevelopmentOnWindows.webp"),
            secondImg: require("../../../assets/images/CmoreiphoneAppDeveloper.webp"),
            thirdImg: require("../../../assets/images/CMoreiPhoneAppDevelopment.webp"),
        },
    },
    {
        margin: "0",
        title: "Expressen Tidning",
        description: "An IOS application for news",
        backgroundImg: require("../../../assets/images/expressionWebDevelopment.webp"),
        content: {
            subHeading: "An IOS application for news",
            mainHeading: "Expressen Tidning",
            bodyContent:
                "Contributed new features to existing app and also helped and refactored the existing code. Technologies used: iPhone Mobile, Objective-C , MVC, Pods",
            firstImg: require("../../../assets/images/ExpressenTidningIphoneApp.webp"),
            secondImg: require("../../../assets/images/ExpressenTidningIphoneApplication.webp"),
            thirdImg: require("../../../assets/images/ExpressenTidningIphoneApplicationiPhoneMobile.webp"),
        },
    },

    {
        margin: "3rem",
        title: "MCDONALDS",
        description: "My Mcdonald's freshly prepared",
        backgroundImg: require("../../../assets/images/McdonaldsSwiftiOSAppDevelopment.webp"),
        content: {
            subHeading: "My Mcdonald's freshly prepared",
            mainHeading: "MCDONALDS",
            bodyContent:
                "Contributed new features to existing app and also helped and refactored the existing code. Technologies used: iOS App, Objective-C , MVC, Pods",
            firstImg: require("../../../assets/images/McdonaldsiosAppDevelopment.webp"),
            secondImg: require("../../../assets/images/McdonaldsiosIphoneAppDevelopment.webp"),
            thirdImg: require("../../../assets/images/McdonaldsIphoneAppDevelopment.webp"),
        },
    },
    {
        margin: "0",
        title: "ASTHMA TUNER",
        description: "Better support for asthmatics",
        backgroundImg: require("../../../assets/images/AsthamaTunerAppDevelopment.webp"),
        content: {
            subHeading: "Better support for asthmatics",
            mainHeading: "ASTHMA TUNER",
            bodyContent:
                "We built an asthma tuner from the ground up for iOS and android using swift 4 and java, and it's been a lifesaver for asthma patients. It helps you keep track of your asthma symptoms and communicate with your doctor. Your clinic devises a treatment strategy to help you control over this life-threatening disease. Technologies used: Swift iOS, Swift 4, JAVA",
            firstImg: require("../../../assets/images/AsthamatunerAndroidApp.webp"),
            secondImg: require("../../../assets/images/AsthamatunerHybridApp.webp"),
            thirdImg: require("../../../assets/images/AsthamatuneriOSApp.webp"),
        },
    },
    {
        margin: "3rem",
        title: "MOOD APP",
        description: "All about having fun and being engaged.",
        backgroundImg: require("../../../assets/images/MoodHybridDevelopment.webp"),
        content: {
            subHeading: "All about having fun and being engaged.",
            mainHeading: "MOOD APP",
            bodyContent:
                "The mood social app is designed from the bottom up by us. Mood creates a comprehensive list of things to do in your city. You can choose exciting activities, places, and people to experience together based on your mood. Now we are dedicated to make it work for both android and iOS users using react native.",
            firstImg: require("../../../assets/images/MoodAndroidiOSApp.webp"),
            secondImg: require("../../../assets/images/MoodHybridApp.webp"),
            thirdImg: require("../../../assets/images/MoodReactNativeApp.webp"),
        },
    },
    {
        margin: "0",
        title: "QUIZ AND GO",
        description: "Test your knowledge and draw conclusions",
        backgroundImg: require("../../../assets/images/QuizAppDevelopment.webp"),
        content: {
            subHeading: "Test your knowledge and draw conclusions",
            mainHeading: "QUIZ AND GO",
            bodyContent:
                "The iOS application Quiz and Go is available on App Store. We created it from the ground up. It's a game that puts your knowledge, creativity, and ability to associate and draw conclusions to the test. The points would be awarded to the user if they were able to form accurate conclusions. Technologies used: iOS App, Objective-C Swift",
            firstImg: require("../../../assets/images/QuizAndGoiOSApp.webp"),
            secondImg: require("../../../assets/images/QuizAndGoiOSAppAndroid.webp"),
            thirdImg: require("../../../assets/images/QuizAndGoiOSAppDeveloper.webp"),
        },
    },

    {
        margin: "3rem",
        title: "VISMA",
        description: "Shaping the future through technology",
        backgroundImg: require("../../../assets/images/VismaiOSAppDevelopment.webp"),
        content: {
            subHeading: "Shaping the future through technology",
            mainHeading: "VISMA",
            bodyContent: "We used a modular approach here, added new features as needed and resolve the issues that arose. Visma Employee is a free mobile business app. It enables quick and easy access to people who are currently receiving their payslips through various Visma payroll solutions. You can view your pay stub whenever and anywhere you choose. Technologies used: Swift iOS, SwiftUI",
            firstImg: require("../../../assets/images/VismaAndroidDevelopment.webp"),
            secondImg: require("../../../assets/images/VismaHybridAppDevelopment.webp"),
            thirdImg: require("../../../assets/images/VismaKotlinDevelopment.webp"),
        },
    },
];