import React from "react";
import { Button } from "@mui/material";
import Arrow from "../../../assets/icons/Arrow.webp";

const ViewWorkButton = () => {
  return (
    <div>
      <Button
        sx={{
          textTransform: "capitalize",
          color: "#000",
          height: "5rem",
          width: "5rem",
          borderRadius: "100px",
          border: "1px solid #000",
          backgroundColor: "#fff",
          fontSize: "8px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <img src={Arrow} width="22px" height="22.5px" alt="Arrow" title="Arrow" loading="lazy" />
        View our Work
      </Button>
    </div>
  );
};

export default ViewWorkButton;
