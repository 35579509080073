import React, { Fragment } from "react";
import { CssBaseline, Container, Box, Typography, } from "@mui/material";
import VerticalLine from "../../../assets/other/VerticalLine.webp";
import Facebook from "../../../assets/icons/Facebook.webp";
import Instagram from "../../../assets/icons/Instagram.webp";
import LinkedIn from "../../../assets/icons/LinkedIn.webp";
import Twitter from "../../../assets/icons/Twitter.webp";
import Navbar from "../Navbar/Navbar";
import { Link } from "react-router-dom";
import ViewWorkButton from "../../atom/ViewWorkButton/ViewWorkButton";


interface Style {
  maxWidth: any;
}

const HeroArea = (props: Style) => {
  return (
    <Fragment>
      <CssBaseline />
      <Container
        disableGutters
        maxWidth={props.maxWidth}
        sx={{
          bgcolor: "#f4f4f4",
          paddingTop: "8rem",
          paddingBottom: "2rem",
        }}
      >
        <Navbar />
        <Container
          maxWidth="md"
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box sx={{ padding: "1rem 0" }}>
            <Typography
              variant="h1"
              sx={{
                fontFamily: "Noto Serif",
                fontWeight: 500,
                fontSize: {
                  lg: "3rem",
                  md: "3rem",
                  sm: "2rem",
                  xs: "2rem",
                },
                width: {
                  lg: "80%",
                  md: "80%",
                  sm: "80%",
                  xs: "100%",
                },
              }}
            >
              A{" "}
              <span style={{ fontWeight: "bold", color: "#6D30BA" }}>
                strong team
              </span>{" "}
              and a unique working environment
            </Typography>
            <Typography
              variant="body1"
              sx={{
                color: "#7a7a7a",
                margin: "1.5rem 0",
                fontSize: {
                  lg: "1.25rem",
                  md: "1.25rem",
                  sm: "1.5rem",
                  xs: "1.5rem",
                },
              }}
            >
              We are a leading mobile app development company based in Stockholm, Sweden.
            </Typography>
            <Link to="/works" style={{ textDecoration: "none" }}>
              <ViewWorkButton />
            </Link>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Box
              sx={{
                display: { xs: "none", sm: "flex" },
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-around",
              }}
            >
              <img
                src={VerticalLine}
                style={{
                  marginTop: ".5rem",
                  marginBottom: ".5rem",
                }}
                alt="verticalline"
                width="1px" height="50px" title="verticalline" loading="lazy"
              />
              <Link to={'//www.facebook.com/sthlmtechpartner/'} target={'_blank'}>
                <img
                  style={{
                    marginTop: ".5rem",
                    marginBottom: ".5rem"
                  }}
                  src={Facebook}
                  width="9px"
                  height="15.5px"
                  alt="facebookIcon"
                  loading="lazy"
                />
              </Link>
              <Link to={'//mobile.twitter.com/sthlmtechprtnr'} target={'_blank'}>
                <img
                  style={{
                    marginTop: ".5rem",
                    marginBottom: ".5rem",
                  }}
                  width="11.5px"
                  height="10.5px"
                  loading="lazy"
                  src={Twitter}
                  alt="twitterIcon"
                />
              </Link>
              <Link to={'//www.instagram.com/sthlmtechpartner.official/?hl=en'} target={'_blank'}>
                <img
                  src={Instagram}
                  style={{
                    marginTop: ".5rem",
                    marginBottom: ".5rem",
                  }}
                  width="11px"
                  height="11px"
                  loading="lazy"
                  alt="instagramIcon"
                />
              </Link>
              <Link to={'//www.linkedin.com/company/sthlm-tech-partner'} target={'_blank'}>
                <img
                  style={{
                    marginTop: ".5rem",
                    marginBottom: ".5rem",
                  }}
                  width="12px"
                  height="12px"
                  loading="lazy"
                  alt="linkedinIcon"
                  src={LinkedIn}
                />
              </Link>
              <img
                src={VerticalLine}
                style={{
                  marginTop: ".5rem",
                  marginBottom: ".5rem",
                }}
                alt="verticalline"
                width="1px" height="50px" title="verticalline" loading="lazy"
              />
            </Box>
          </Box>
        </Container>
      </Container>
    </Fragment>
  );
};

export default HeroArea;
